import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import upLoad from '@/assets/img/upload_photo3.jpg';
import store from "@/store";
import {
    required,
    maxLength,
    integer
} from "vuelidate/lib/validators";

export default {
    name: "inOutcomeEdit",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
            inoutData: {
                title: '',
                type:'',
                class_id: '',
                payment_method_id: null,
                payment_date: '',
                amount:'',
                incharge_name: '',
                payment_screenshot: '',
                remark: '',
                removeStatus: false
            },
            year: '',
            paymentMethodArr: null,
            isLoading: false,
            submitted: false,
            upLoad,
            ssImage: '',
            campusId: store.state.user.campus_id,
            receivedAmtValidate: false,
            receiverValidate: false,
            paidValidate: false,
            payerValidate: false,
            disabled: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            received_amt: '',
            paid_amt: '',
            classArr:null,
        };
    },

    validations: {
        inoutData: {
            title: {
                required,
                maxLength: maxLength(100)
            },
            payment_method_id: {
                required
            },
            payment_date: {
                required
            },
            incharge_name: {
                required,
                maxLength: maxLength(100)
            },
           class_id: {
                required
            },
            remark: {
                maxLength: maxLength(1000)
            },
            amount:{
                required,
                integer,
                maxLength: maxLength(9)
            }
        },
    },

    methods: {
        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.isLoading = false;
                    this.getClass(this.academicYear.id)
                    
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getPaymentMethod() {
            axios.get('paymentMethod/getPaymentMethodArray')
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Payment Method'
                    }];
                    this.paymentMethodArr = $defaultSelectOption.concat(res.data[0]);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        removeImg() {
            this.ssImage = '';
            document.getElementById('image').value = '';
            if (this.inoutData.img_path != null) {
                this.inoutData.removeStatus = true;
                this.inoutData.img_path = '';
            }
        },

        getDetail() {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios.get(`transaction/detail/${this.$route.params.id}`, request)
                .then(res => {
                    if (res.data) {
                        this.inoutData = res.data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Income/Expense Detail API Error", error);
                });
        },

        imgUpload(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return null;
            } else {
                if (e.target.files[0].type.includes("image/")) {
                    if (e.target.files[0].type == "image/gif") {
                        this.$notification.error("File not supported!", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        return null;
                    }
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.target.result.length > 5242880) {
                            this.$notification.error(this.imageLargeMsg, {
                                timer: 3,
                                position: "bottomCenter",
                            });
                        } else {
                            this.ssImage = e.target.result;
                        }
                    }
                    reader.readAsDataURL(files[0]);
                } else {
                    this.$notification.error("File not supported!", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    return null;
                }
            }
        },

        update() {
            this.submitted = true;
            if (this.$v.$invalid || this.inoutData.class_id == '' || this.inoutData.class_id == 0) {
                return;
            }

            if (this.ssImage) {
                this.inoutData.payment_screenshot = this.ssImage;
                this.inoutData.removeStatus = false;
            }
            this.inoutData.loginID = this.$store.state.user.id;
            this.inoutData.campus_id = this.$store.state.user.campus_id;
            this.disabled = true;
            axios.post(`transaction/update/${this.$route.params.id}`, this.inoutData)
                .then(res => {
                    if (res.data) {
                        this.$notification.success("Transaction Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "transactionList"
                        });
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        this.getPaymentMethod();
        this.getThisAcademicYear();
        
    },
};
