import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { required, maxLength } from "vuelidate/lib/validators";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "attendanceStudentEdit",
  components: {
    MainLayout,
    flatPickr,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      attendanceData: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    attendanceData: {
      date: {
        required
      },
      status: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      }
    }
  },

  methods: {
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getAttendanceData () {
      var request = {
        params: {
          id: this.$route.params.id
        }
      }
      axios.get("/studentAttendance/detail", request)
        .then(response => {
          this.attendanceData = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Student Detail API Error", error);
        });
    },

    confirmUpdate(){
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        id: this.$route.params.id,
        class_id: this.attendanceData.class_id,
        student_ids : this.attendanceData.student_id,
        date: this.attendanceData.date,
        status: this.attendanceData.status,
        remark: this.attendanceData.remark,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }

      axios.post("/studentAttendance/update", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Attendance Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }          
          this.disabled = false;
          this.isLoading = false;
          console.log("Attendance Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceStudent/list"
      });
    },

  },
  mounted() {
    this.isLoading = true;
    this.getAttendanceData();
  },
};
