import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
  },

  data() {
    return {
      name: '',
      start_date: '',
      end_date: '',
      attendanceTimeArr: null,
      attendanceList: {},
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
      searchBtnStatus: false,
      start_value: '08:16:00',
      end_value: '11:00:00',
    };
  },

  methods: {
    getAttendanceList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/teacherAttendance/list?page=" + page, request)
        .then(response => {
          this.search_status = false;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Attendance List API Error", error);
        });
    },

    search(page = 1) {
      this.searchBtnStatus = true;
      this.isLoading = true;
      var request = {
        params: {
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/teacherAttendance/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Attendance Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.start_date = '';
      this.end_date = '';
      this.academic_year = '';
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(data) {
      this.isLoading = true;
      var request = {
        params: {
          teacher_id: data.teacher_id,
          date: data.date
        }
      }
      axios.delete("/teacherAttendance/delete", request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getAttendanceList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Delete Error->", error.response.data);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/attendanceTeacher/create"
      });
    },

    showEdit(data) {
      this.$router.push({
        name: "attendanceTeacherEdit",
        params: {
          teacher_id: data.teacher_id,
          date: data.date
        }
      });
    },

    showDetail(data) {
      this.$router.push({
        name: "attendanceTeacherDetail",
        params: {
          teacher_id: data.teacher_id,
          date: data.date
        }
      });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/teacherAttendance/excelDownload', {
        params: {
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id,
          searchStatus: this.searchBtnStatus
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `teacher_attendance_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getAttendanceList();
  },
};
