import VueMonthlyPicker from 'vue-monthly-picker'
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "SalaryList",
  components: {
    MainLayout,
    VueMonthlyPicker,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {},
      salaryData: {},
      total: null,
      isLoading: false,
      selectedMonth: null,
      month: null
    };
  },

  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        const adjustedMonth = new Date(newValue);
        adjustedMonth.setMonth(adjustedMonth.getMonth() + 1); // Add one month to adjust for the offset
        this.month = adjustedMonth.toISOString().slice(0, 7); // Extract month and year
      } else {
        this.month = null;
      }
    },
  },

  methods: {
    getSalary(page = 1) {
      axios.get('salary/getList?page=' + page, { params: { campus_id: this.$store.state.user.campus_id } })
        .then(res => {
          if (res.data) {
            this.salaryData = res.data;
            this.salaryData.data.month = this.month;
            this.total = this.salaryData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = {}
      this.month = null;
      this.selectedMonth = null;
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('salary/search?page=' + page, {
        params: {
          teacher_name: this.searchData.teacher_name,
          month: this.month,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.salaryData = res.data;
            this.total = this.salaryData.meta.total;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/salary/excelDownload', {
        params: {
          teacher_name: this.searchData.teacher_name,
          month: this.month,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `salary_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`salary/delete/${id}`)
        .then(res => {
          if (res.data.success == true)
            this.isLoading = false;
            this.getSalary();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

  },

  mounted() {
    this.isLoading = true;
    this.getSalary();
  },
};
