import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from "@/store";

export default {
    name: "inOutcomeDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            inout: [],
            classArr: [],
            status: '',
            academicYearArr: null,
            paymentMethodArr: null,
            isLoading: false,
            campusId: store.state.user.campus_id,
            noDataMsg: CONFIG.NO_DATA_EXIST,
        };
    },

    methods: {
        // getAcademicYear() {
        //     axios.get('getAcademicYear')
        //         .then(res => {
        //             let $defaultSelectOption = [{
        //                 'id': 0,
        //                 'text': 'Select'
        //             }];
        //             this.academicYearArr = $defaultSelectOption.concat(res.data);
        //         }).catch((error) => {
        //             if (error.response.status == 401) {
        //                 this.$store.commit('logout');
        //                 window.location.replace('login');
        //             }
        //             this.isLoading = false;
        //         });
        // },

        // getPaymentMethod() {
        //     axios.get('getPaymentMethod')
        //         .then(res => {
        //             let $defaultSelectOption = [{
        //                 'id': 0,
        //                 'text': 'Select Payment Method'
        //             }];
        //             this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        //         }).catch((error) => {
        //             if (error.response.status == 401) {
        //                 this.$store.commit('logout');
        //                 window.location.replace('login');
        //             }
        //             this.isLoading = false;
        //         });
        // },

        // getClass() {
        //     axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } })
        //         .then(res => {
        //             this.classArr = res.data;
        //         }).catch(error => {
        //             if (error.response.status == 401) {
        //                 this.$store.commit('logout');
        //                 window.location.replace('login');
        //             }
        //             this.isLoading = false;
        //         });
        // },

        getDetail() {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios.get(`transaction/detail/${this.$route.params.id}`, request)
                .then(res => {
                    if (res.data) {
                        this.inout = res.data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("InOutCome Detail API Error", error);
                });
        },

        pdfDownload() {
            axios.get("/transaction/pdfDownload/" + this.$route.params.id, {
                params: {
                    campus_id: this.campusId
                },
                'responseType': 'arraybuffer'
            })
                .then((response) => {
                    this.isLoading = true;
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let date = new Date();
                    link.setAttribute('download', `TransactionInvoice_${date.getFullYear()}${(date.getMonth() + 1) > 9 ? '' : '0'}${date.getMonth() + 1}${(date.getDate()) > 9 ? '' : '0'}${date.getDate()}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }

    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        // this.getClass();
        // this.getPaymentMethod();
        // this.getAcademicYear();
    },
};
