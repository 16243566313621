import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "CourseList",
    components: {
        MainLayout,
        Select2Multiple,
        Loading,
        flatPickr,
        AdvancedLaravelVuePaginate,
        CONFIG,
    },

    data() {
        return {
            subjectTeacherData: {},
            searchData: {
                class_id: null,
                teacher_id: '',
                subject_id: '',
            },
            total: null,
            classArr: null,
            teacherArr: null,
            subjectArr: null,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            campus_id: '',
            acd_year_id : ''
        };
    },

    methods: {
        getList(page = 1) {
            axios.get('subjectTeacher/list?page=' + page,
                {
                    params: {
                        campus_id: this.$store.state.user.campus_id
                    }
                })
                .then(res => {
                    if (res.data) {
                        this.subjectTeacherData = res.data;
                        this.total = this.subjectTeacherData.total;

                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (!error && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getGradeAndClass() {
            var request = {
                params: {
                  campus_id: this.$store.state.user.campus_id
                }
              }
            axios.get("class/getGradeAndClass", request)
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        search(page = 1) {
            this.isLoading = true;
            axios.get('subjectTeacher/search?page=' + page, {
                params: {
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    subject_name: this.searchData.subject_name,
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then((res) => {
                    this.isLoading = false;
                    if (res.data) {
                        this.subjectTeacherData = res.data;
                        this.total = this.subjectTeacherData.total;
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            } else {
                console.log("no");
            }
        },

        delete(id) {
            this.isLoading = true;
            axios.delete(`subjectTeacher/delete/${id}`)
                .then(res => {
                    if (res.data) {
                        this.getList();
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        reset() {
            this.searchData.class_id = null;
            this.searchData.teacher_name = '';
            this.searchData.subject_name = '';
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('subjectTeacher/excelDownload', {
                params: {
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    subject_name: this.searchData.subject_name,
                    campus_id: this.$store.state.user.campus_id,
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    let date = new Date();
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `subject_assign_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showCreate() {
            this.$router.push({
                path: "/subjectTeacher/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "subjectTeacherEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(id) {
            this.$router.push({
                name: "subjectTeacherDetail",
                params: {
                    id: id
                }
            });
        },
    },

    mounted() {
        this.getList();
        this.getGradeAndClass();
    },


}
