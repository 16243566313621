import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from "@/store";

export default {
    name: "CourseDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            courseData: [],
            isLoading: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
        };
    },

    methods: {
        getDetail() {
            axios.get('course/detail/' + this.$route.params.id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then((res) => {
                if (res.data) {
                    if( !res.data.campus_id || res.data.campus_id !== store.state.user.campus_id){
                        this.$notification.error("The course does not exist on this campus", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "courseList"
                        });
                    }
                    this.courseData = res.data;
                }
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
    },
};
