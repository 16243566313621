import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  minLength,
  email,
  numeric,
  not,
  sameAs
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../store';
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "ParentCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      parentData: {},
      nrcData: {},
      pwdData: {},
      // campus_id: '',
      submitted: false,
      isLoading: false,
      disabled: false,
      existingParent: false,
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,

      guardian1_nrc_number: "",
      guardian1NrcKey: "",
      guardian1SelectedNrcName: "",
      guardian1SelectedNrcNaing: "",
      guardian1DistrictList: [],
      guardian1NaingList: [],

      guardian2_nrc_number: "",
      guardian2NrcKey: "",
      guardian2SelectedNrcName: "",
      guardian2SelectedNrcNaing: "",
      guardian2DistrictList: [],
      guardian2NaingList: [],

      nrcName: [],
      emailExists: null,
      nrc1Exists: {exists: null},
      nrc2Exists: {exists: null},
      nrcSame: null,
      parentExists: null,
    };
  },

  validations: {
    parentData: {
      guardian1_name: {
        required,
        maxLength: maxLength(50)
      },
      guardian1_job: {
        maxLength: maxLength(200)
      },
      guardian2_name: {
        maxLength: maxLength(50)
      },
      guardian2_job: {
        maxLength: maxLength(200)
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11),
        notSameAs: not(sameAs('phone1'))
      },
      address: {
        required,
        maxLength: maxLength(1000)
      }
    },

    nrcData: {
      guardian1_nrc_number: {
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      guardian2_nrc_number: {
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
    },

    pwdData: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
    }
  },

  methods: {
    async checkDuplicateEmail() {
      try {
          const response = await axios.get("checkDuplicateEmail", {
              params: {
                  model: 'Parents',
                  email: this.parentData.email,
              },
          });
          this.emailExists = response.data.exists;
          if(this.emailExists) {
            this.$notification.error("Email already existed.", {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.disabled = false;
      } catch (error) {
          console.error('An error occurred:', error);
      }
    },

    async checkDuplicateNrc(nrcNumber, guardianNo, updateVar) {
      try {
        const response = await axios.get("parent/checkDuplicateNrc", {
          params: {
            nrc_number: nrcNumber,
          },
        });
        updateVar.exists = response.data.exists;
        if (updateVar.exists) {
          this.$notification.error(guardianNo + " Duplicate NRC exists.", {
            timer: 3,
            position: "bottomCenter",
          });
        }
        this.disabled = false;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async checkDuplicateParentByNameAndPh() {
      try {
        const response = await axios.get("parent/checkDuplicateParentByNameAndPh", {
          params: {
            guardian1_name: this.parentData.guardian1_name,
            phone1: this.parentData.phone1
          },
        });
        this.parentExists = response.data.exists;
        if (this.parentExists) {
          this.$notification.error("Duplicate Parent With Guardian 1 Name and Phone 1 Exists.", {
            timer: 3,
            position: "bottomCenter",
          });
        }
        this.disabled = false;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
        const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcTownship() {
      try {
        const response = await axios.get('getNrcTownship')
        if (response) {
          this.isLoading = false;
          this.nrcNameJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    nrcOnChange(event, option) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.$set(this, option + 'NrcKey', optionText)
      } else {
        this.$set(this, option + 'NrcKey', optionValue)
      }
      this.$set(this, option + 'SelectedNrcName', "");
      this.$set(this, option + 'DistrictList', this.changeNrcName(optionValue));
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event, op) {
      var optionValue = event.target.value;
      if (!optionValue) {
        if (op == 'guardian1') {
          this.guardian1SelectedNrcNaing = "";
          this.nrcData.guardian1_nrc_number = "";
        } else {
          this.guardian2SelectedNrcNaing = ""
          this.nrcData.guardian2_nrc_number = ""
        }
      }
      op == 'guardian1' ? this.guardian1NaingList = this.naingJson : this.guardian2NaingList = this.naingJson;
    },

    mergeNRC(op) {
      if (op == 'guardian1') {
        return convertNumberService.convertEng2Mm(
          this.guardian1NrcKey +
          "/" +
          this.guardian1SelectedNrcName +
          this.guardian1SelectedNrcNaing +
          this.nrcData.guardian1_nrc_number
        );
      } else {
        return convertNumberService.convertEng2Mm(
          this.guardian2NrcKey +
          "/" +
          this.guardian2SelectedNrcName +
          this.guardian2SelectedNrcNaing +
          this.nrcData.guardian2_nrc_number
        );
      }
    },

    async confirmRegister() {
      this.submitted = true;
      if (this.parentData.mail) {
        await this.checkDuplicateEmail();
      }

      var guardian1NrcNumber = this.guardian1NrcKey ? this.mergeNRC('guardian1') : null;
      var guardian2NrcNumber = this.guardian2NrcKey ? this.mergeNRC('guardian2') : null;
      if ((guardian1NrcNumber !== null && guardian2NrcNumber !== null) && (guardian1NrcNumber == guardian2NrcNumber)) {
        this.nrcSame = true;
        this.disabled = false;
        this.$notification.error("Guardian 1's NRC cannot be the same as Guardian 2's NRC.", {
          timer: 3,
          position: "bottomCenter",
        });
      } else {
        if (guardian1NrcNumber != null) {
          await this.checkDuplicateNrc(guardian1NrcNumber, "Guardian 1", this.nrc1Exists);
        }
        if (guardian2NrcNumber != null) {
          await this.checkDuplicateNrc(guardian2NrcNumber, "Guardian 2", this.nrc2Exists);
        }
      }

      if(this.parentData.phone1 && this.parentData.guardian1_name) {
        await this.checkDuplicateParentByNameAndPh();
      }

      if (this.$v.parentData.$invalid ||
        this.$v.nrcData.$invalid || this.nrcSame || this.emailExists || this.nrc1Exists.exists || this.nrc2Exists.exists || this.parentExists) {
        this.nrcSame = null;
        return;
      } else if (this.$v.pwdData.$invalid) {
        return;
      } else if (this.pwdData.password != this.pwdData.confirm_password) {
        this.$notification.error("Password and confirm password do not match.", {
          timer: 3,
          position: "bottomCenter",
        });
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        guardian1_name: this.parentData.guardian1_name,
        guardian1_job: this.parentData.guardian1_job,
        guardian2_name: this.parentData.guardian2_name,
        guardian2_job: this.parentData.guardian2_job,
        password: this.pwdData.password,
        guardian1_nrc_number: this.guardian1NrcKey ? this.mergeNRC('guardian1') : '',
        guardian2_nrc_number: this.guardian2NrcKey ? this.mergeNRC('guardian2') : '',
        email: this.parentData.email,
        phone1: this.parentData.phone1,
        phone2: this.parentData.phone2,
        address: this.parentData.address,
        // campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }
      axios.post("/parent/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Parent Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "parentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Parent Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/parent/list"
      });
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
  },
};
