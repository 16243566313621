import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
  required
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '@/store';

export default {
  name: "CampusGradeEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      periodArr: [],
      gradeArr: [],
      campusGradeData: [],
      submitted: false,
      disabled: false,
      campus_name: store.state.user.campus_master.campus_name,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    campusGradeData: {
      acd_year_id: {
        required
      },
      grade_id: {
        required
      }
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('period/periodList')
        .then(res => {
          this.periodArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getGrade() {
      axios.get("grade/gradeList")
        .then(res => {
          this.gradeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getDetail() {
      axios.get(`campusGrade/detail/${this.$route.params.id}`)
        .then(res => {
          if (res.data) {
            this.campusGradeData = res.data;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    update() {
      this.submitted = true;
      this.disabled = true;
      if (this.$v.$invalid) {
        return;
      }

      this.campusGradeData.login_id = this.$store.state.user.id;
      axios.post(`campusGrade/update/${this.$route.params.id}` , this.campusGradeData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Campus Grade Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "campusGradeList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          this.disabled = false;
          this.isLoading = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getAcademicYear();
    this.getGrade();
    this.getDetail();
  },
};
