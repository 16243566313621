import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      teacherId:'',
      campusId: '',
      attendanceInfo: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      attendanceTimeArr: null,
    };
  },

  methods: {
    getAttendanceTime() {
      axios.get('getAttendanceTime')
        .then(res => {
          this.attendanceTimeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAttendanceDetail () {
      var request = {
        params: {
          teacher_id: this.$route.params.teacher_id,
          date: this.$route.params.date,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get("/teacherAttendance/detail", request)
        .then(response => {
          this.attendanceInfo = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Teacher Detail API Error", error);
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceTeacher/list"
      });
    },
  },
  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.isLoading = true;
    this.getAttendanceTime();
    this.getAttendanceDetail();
  },
};
