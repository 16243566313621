import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
  name: "attendanceStudentList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
  },

  data() {
    return {
      name:'',
      year: '',
      class_id: '',
      status:'',
      start_date: '',
      end_date: '',
      attendanceStatusArr: null,
      academicYearArr: null,
      selectedStatus: 2,
      attendanceList:{},
      classArr: [],
      selectedYear: 0,
      selectedClass: 0,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
      searchBtnStatus: false
    };
  },

  methods: {
    getAttendanceStatus() {
      axios.get('getAttendanceStatus')
        .then(res => {
          this.attendanceStatusArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
            campus_id: store.state.user.campus_id
        }
    })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
          this.getClass(this.selectedYear);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      fieldName == 'status' && val == 2 ? this.$set(this, fieldName, '') : this.$set(this, fieldName, val);

      if (fieldName == 'year') {
        // this.isLoading = true;                 
        this.classArr = [];
        this.class_id = '';
        this.selectedClass = '';
        this.getClass(val);
      }

    },

    getAttendanceList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/studentAttendance/list?page=" + page , request)
        .then(response => {
          this.search_status = false;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance List API Error", error);
        });
    },

    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
        params: {
            campus_id: store.state.user.campus_id
        }
      })
      .then(res => {
          let $defaultSelectOption = [{
              'id': 0,
              'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    search(page = 1) {
      this.searchBtnStatus = true;
      this.isLoading = true;
      var request = {
        params: {
          name: this.name,
          class_id: this.class_id,
          year: this.year,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/studentAttendance/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Attendance Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.year = '';
      this.class_id = '';
      this.start_date = '';
      this.end_date = '';
      this.status = '';
      this.selectedStatus = 2;
      this.selectedClass = this.selectedYear = 0;
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteClass(id);
      } else {
        console.log("no");
      }
    },

    deleteClass(id) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      this.isLoading = true;
      axios.delete("/studentAttendance/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getAttendanceList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Delete Error->", error.response.data);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/attendanceStudent/create"
      });
    },

    showEdit(attendanceId) {
      this.$router.push({
        name: "attendanceStudentEdit",
        params: {
          id: attendanceId
        }
      });
    },

    showDetail(attendanceId) {
      this.$router.push({
        name: "attendanceStudentDetail",
        params: {
          id: attendanceId
        }
      });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/studentAttendance/excelDownload', {
        params: {
          name: this.name,
          class_id: this.class_id,
          year: this.year,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id,
          searchStatus: this.searchBtnStatus
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `student_attendance_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getAttendanceStatus();
    this.getAcademicYear();
    this.getAttendanceList();
  },
};
