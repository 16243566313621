import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "adminDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      itemData: [],
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getDetail() {
      axios.get(`item/detail/${this.$route.params.id}`)
        .then(res => {
          if (res.data) {
            this.itemData = res.data.data;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
