import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";

export default {
    name: "GradeFeeDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            campus_grade_id: '',
            annual_fee: '',
            monthly_fee: '',
            grade_name: '',
            academic_year: '',
            gradeFeeData: {}
        };
    },

    methods: {
        getGradeFeeById() {
            axios.get('gradeFee/detail/' + this.$route.params.id, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then((response) => {
                    if (response.data != undefined) {
                        this.campus_grade_id = response.data.campus_grade_id;
                        this.annual_fee = response.data.annual_fee;
                        this.monthly_fee = response.data.monthly_fee;
                        this.grade_name = response.data.grade_name;
                        this.academic_year = response.data.period_name;
                        this.gradeFeeData = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        showList() {
            this.$router.push({
                path: "/gradeFee/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getGradeFeeById();
    },
};
