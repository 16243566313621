import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
    required,
    maxLength,
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "HolidayEdit",
    components: {
        MainLayout,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            disabled: false,
            holidayData: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            date: '',
            description: '',
            submitted: false,
        };
    },

    validations: {
        holidayData: {
            date: {
                required
            },
            description: {
                maxLength: maxLength(1000)
            },
        },
    },

    methods: {
        getHolidayEdit() {
            axios.get("/holiday/detail/" + this.$route.params.id)
                .then(response => {
                    this.holidayData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Holiday Detail API Error", error);
                });
        },

        confirmUpdate() {
            this.submitted = true;
            if (this.$v.$invalid || this.holidayData.date == '' || this.holidayData.date == 0) {
                return;
            }
            this.update();
        },

        update() {
            this.disabled = true;
            var id = this.$route.params.id
            var request = {
                campus_id: store.state.user.campus_id,
                date: this.holidayData.date,
                description: this.holidayData.description,
                updated_id: store.state.user.id,
            }
            axios.post("/holiday/update/" + id, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.success == true) {
                        this.$notification.success("Holiday Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "holidayList"
                        });
                    } else {
                        this.disabled = false;
                        this.$notification.error(response.data.message, {
                          timer: 3,
                          position: "bottomCenter"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.isLoading = false;
                    console.log("Holiday Edit API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/holiday/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getHolidayEdit();
    },
};
