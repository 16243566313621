import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import CONFIG from "@/assets/js/config.js";

export default {
  name: "campusList",
  components: {
    MainLayout,
    Loading,
    AdvancedLaravelVuePaginate,
    CONFIG,
  },

  data() {
    return {
      campusData: {},
      removeId: '',
      searchData: {
        campus_name: '',
        phone1: '',
        address: ''
      },
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false
    };
  },

  methods: {
    getCampus(page = 1) {
      axios.get('campus/getAll?page=' + page)
        .then(res => {
          if (res.data) {
            this.campusData = res.data;
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`campus/delete/${id}`)
        .then(res => {
          if (res.data) {
            this.getCampus();
            this.isLoading = false;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    reset() {
      this.searchData.campus_name = '';
      this.searchData.phone1 = '';
      this.searchData.address = '';
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('campus/search?page=' + page, {
        params: {
          campus_name: this.searchData.campus_name,
          phone1: this.searchData.phone1,
          address: this.searchData.address
        }
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data) {
            this.campusData = res.data;
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/campus/excelDownload', {
        params: {
          campus_name: this.searchData.campus_name,
          phone1: this.searchData.phone1,
          address: this.searchData.address
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.isLoading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          let date = new Date();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `campus_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getCampus();
  },
};
