import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "CampusGradeList",
  components: {
    MainLayout,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
    CONFIG
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      periodArr: [],
      campusGradeData: {},
      search_period_id: null,
      gradeArr: [],
      classList: {},
      academicYearArr: null,
      grade_id: '',
      academic_year: '',
      section: '',
      selectedGrade: 0,
      selectedYear: this.$store.state.academicYear,
      search_status: false
    };
  },

  methods: {
    getAcademicYear() {
      axios.get('period/periodList')
        .then(res => {
          this.periodArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getCampusGrade(page = 1) {
      axios.get("/campusGrade/list?page=" + page, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.campusGradeData = res.data;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`campusGrade/delete/${id}`)
        .then(res => {
          if (res.data)
            this.getCampusGrade();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    reset() {
      this.search_period_id = null;
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get("/campusGrade/search?page=" + page, {
        params: {
          acd_year_id: this.search_period_id,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((res) => {
          this.campusGradeData = res.data;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/campusGrade/excelDownload', {
        params: {
          acd_year_id: this.search_period_id,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `campus_grade_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getCampusGrade();
    this.getAcademicYear();
  },
};
