import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '@/store';

export default {
  name: "adminDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      admin: [],
      isLoading: false,
      campusId: store.state.user.campus_master.campus_code,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      config: CONFIG,
    };
  },

  methods: {
    getDetail() {
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get(`admin/detail/${this.$route.params.id}`, request)
        .then(res => {
          if (res.data) {
            this.admin = res.data;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Admin Detail API Error", error);
        })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
