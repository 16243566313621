import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "timetableMetaCreate",
  components: {
    MainLayout,
    Loading,
    flatPickr,
  },

  data() {
    return {
      submitted: false,
      selectedClass: 0,
      isLoading: false,
      metaData: {},
      period_name: null,
      class_name: null,
      selectBoxes: [],
      timeArr: [],
      noDataMsg: CONFIG.NO_DATA_EXIST,

      configDateTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        minuteIncrement: 1,
      },
    };
  },

  methods: {
    getDetail() {
      axios.get(`timetableMeta/detail/${this.$route.params.id}`)
        .then(res => {
          this.metaData = res.data;
          if (Object.keys(this.metaData).length != 0) {
            this.period_name = this.metaData[0].period_name;
            this.class_name = this.metaData[0].class_name
            this.metaData.forEach(item => {
              if (item.type === 2) {
                var [start, end] = item.value.split("~");
                const startHour = parseInt(start.split(':')[0]);
                const endHour = parseInt(end.split(':')[0]);
                if (startHour > 12) {
                  start = `${startHour - 12}:${start.split(':')[1]}`;
                }
                if (endHour > 12) {
                  end = `${endHour - 12}:${end.split(':')[1]}`;
                }
                this.timeArr.push({ start_time: start, end_time: end });
              }
            });
          }

          this.isLoading = false;
        })
      .catch(error => {
        if (error.res.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
