import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store'

export default {
  name: "adminCreate",
  components: {
    MainLayout,
    flatPickr,
  },

  data() {
    return {
      periodData: {
        period_name: '',
        start_date: '',
        end_date: '',
        login_id: store.state.user.id
      },
      isError: false,
      submitted: false,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
    };
  },

  validations: {
    periodData: {
      period_name: {
        required,
        maxLength: maxLength(100)
      },
      start_date: {
        required
      },
      end_date: {
        required
      }
    },
  },

  methods: {
    create() {
      this.submitted = true;
      if (this.periodData.start_date && this.periodData.end_date && new Date(this.periodData.end_date) <= new Date(this.periodData.start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }

      if (this.$v.$invalid || this.isError) {
        return;
      }

      this.disabled = true;
      axios.post('period/create', this.periodData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Academic Period Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'periodList'
            })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch((error) => {
          this.disabled = false;
          this.isLoading = false;
          if (error.response) {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
          }
        });
    }
  },
};
