import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "GradingList",
    components: {
        MainLayout,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            selectedYear: 0,
            selectedClass: 0,
            student_id: 0,
            subject_id: '',
            academic_year: 0,
            exam_name: '',
            class_id: 0,
            academicYearArr: null,
            classArr: [],
            subjectArr: [],
            studentNameArr: [],
            gradingList: {},
            isLoading: false,
            search_status: false,
        };
    },

    methods: {
        getAcademicYear() {
            axios.get('getAcademicYear', {
              params: {
                  campus_id: store.state.user.campus_id
              }
          })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select'
                    }];
                    this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                    this.getClass(this.selectedYear);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        
        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
            if (fieldName == "academic_year") {
                this.subject_id = 0;
                this.student_id = 0;
                this.selectedClass = 0;
                this.classArr = [];
                this.getClass(val);
            }
            if (fieldName == "class_id") {
                this.subjectArr = [];
                this.studentNameArr = [];
                this.subject_id = 0; 
                this.student_id = 0;
                this.getStudentName(val);
                this.getSubjectByClassId(val);
            }
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.subjectArr = [];
                    this.studentNameArr = [];
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getStudentName($classId) {
            axios.get("student/getStudentName/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student Name'
                    }];
                    this.studentNameArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getSubjectByClassId($classId) {
            axios.get("/class/subjectByClassId/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        acd_year_id: this.academic_year
                    }
                })
                .then((response) => {
                    let $defaultSelectOption = [
                        {
                            'id': 0,
                            'text': 'Select Subject'
                        }
                    ];
                    this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },

        getGradingList(page = 1) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios
                .get("/grading/list?page=" + page, request)
                .then((response) => {
                    this.isLoading = false;
                    this.search_status = false;
                    this.gradingList = response.data;
                })
                .catch((error) => {                    
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }   
                    this.isLoading = false;
                    console.log("Grading List API Error", error);
                });
        },

        reset() {
            this.selectedYear = 0;
            this.academic_year = 0;
            this.selectedClass = 0;
            this.class_id = 0;
            this.student_id = 0;
            this.subject_id = 0;
            this.exam_name = '';
            this.classArr = [];
            this.studentNameArr = [];
            this.subjectArr = [];
        },

        searchGrading(page = 1) { 
            this.isLoading = true;
            var request = {
                params: {
                    academic_year: this.academic_year,
                    class_id: this.class_id,
                    student_id: this.student_id,
                    subject_id: this.subject_id,
                    exam_name: this.exam_name,
                    campus_id: store.state.user.campus_id
                },
            };
            axios
                .get("/grading/search?page=" + page, request)
                .then((response) => {
                    this.search_status = true;
                    this.gradingList = response.data;
                    this.isLoading = false;
                })
                .catch((error) => { 
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grading Search API Error", error);
                });
        },
        showCreate() {
            this.$router.push({
                path: "/grading/create"
            });
        },
        
        showDetail(student_id, exam_id) {
             this.$router.push({
                 name: "gradingDetail",
                 params: {
                    student_id: student_id,
                    exam_id: exam_id
                 }
             });
        },

        showEdit(student_id, exam_id) {
            this.$router.push({
                name: "gradingEdit",
                params: {
                    student_id: student_id,
                    exam_id: exam_id
                }
            });
        },

        confirmDelete(student_id, exam_id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteGrading(student_id, exam_id);
            } else {
                console.log("no");
            }
        },

        deleteGrading(student_id, exam_id) {
            this.isLoading = true;
            axios.post("/grading/delete/" + student_id + '/' + exam_id, {
                    campus_id: store.state.user.campus_id
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.isLoading = true;
                        this.getGradingList();
                    } else {
                        this.$notification.error("Grading data is already deleted.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "gradingList"
                        });
                        this.getGradingList();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grading Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/grading/excelDownload', {
                params: {
                    academic_year: this.academic_year,
                    class_id: this.class_id,
                    student_id: this.student_id,
                    subject_id: this.subject_id,
                    exam_name: this.exam_name,
                    campus_id: store.state.user.campus_id
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `grading_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                   if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                   }
                    this.isLoading = false;
                });
        }
    },
    
    mounted() {
        this.isLoading = true;
        this.getGradingList();
        this.getAcademicYear();
    },
};
