import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import axios from 'axios';
import store from '@/store';

export default {
  name: "adminList",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
    CONFIG,
    AdvancedLaravelVuePaginate
  },

  data() {
    return {
      adminData: {},
      searchData: {
        name: '',
        code: '',
        campus_name: '',
        role: 0
      },
      adminRole: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      adminNo: store.state.user.admin_no,
      campusId: store.state.user.campus_id,
    };
  },

  methods: {
    getAdminRole() {
      axios.get('getAdminRole')
        .then(res => {
          this.adminRole = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAdmin(page = 1) {
      axios.get('admin/list?page=' + page, { params: { admin_no: this.adminNo, campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.adminData = res.data;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.delete(`admin/delete/${id}`, request)
        .then(res => {
          if (res.data)
            this.getAdmin();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('admin/search?page=' + page, {
        params: {
          name: this.searchData.name,
          code: this.searchData.code,
          campus_name: this.searchData.campus_name,
          role: this.searchData.role,
          campus_id: this.campusId
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.adminData = res.data;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    reset() {
      this.searchData.name = '';
      this.searchData.code = '';
      this.searchData.campus_name = '';
      this.searchData.role = 0
    },

    login(id, campusId, status, name) {
      if (status == 1) {
        alert(name + ' is inactive.');
      }
      else {
        store.dispatch("loginByBtn", { loginId: id, campus_id: campusId })
          .then(() => {
            window.location.replace('/');
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
      }
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/admin/excelDownload', {
        params: {
          name: this.searchData.name,
          code: this.searchData.code,
          campus_name: this.searchData.campus_name,
          role: this.searchData.role,
          campus_id: this.campusId
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `admin_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }

  },

  mounted() {
    this.isLoading = true;
    this.getAdminRole();
    this.getAdmin();
  },
};
