import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  mapGetters
} from "vuex";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "TeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {
        name: '',
        teacher_no: '',
        gender: 0,
        start_date: null,
        end_date: null
      },
      genderArr: [
        {
          id: 0,
          text: "All",
        },
        {
          id: 1,
          text: "Male",
        },
        {
          id: 2,
          text: "Female",
        },
      ],
      activeTeacherData: {},
      leavedTeacherData: {},
      isLoading: false,
      invalidFile: false,
      teacherFile: '',
      teacherFileName: '',
      disabled: false,
      submitted: false,
      templatelLink: '',
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  validations: {
    teacherFile: {
      required
    }
  },

  methods: {
    getActiveTeacherList(page = 1) {
      axios.get("/teacher/list?page=" + page, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((response) => {
          this.activeTeacherData = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getLeavedTeacherList(page = 1) {
      axios.get("/teacher/leavedList?page=" + page, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((response) => {
          this.leavedTeacherData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData.name = '';
      this.searchData.teacher_no = '';
      this.searchData.gender = 0;
      this.searchData.start_date = '';
      this.searchData.end_date = '';
    },

    search() {
      this.isLoading = true;
      this.searchActiveTeacher();
      this.searchLeavedTeacher();
    },

    searchActiveTeacher(page = 1) {
      axios.get("teacher/search/result?page=" + page, {
          params: {
            name: this.searchData.name,
            teacher_no: this.searchData.teacher_no,
            gender: this.searchData.gender,
            start_date: this.searchData.start_date,
            end_date: this.searchData.end_date,
            campus_id: this.$store.state.user.campus_id,
            leavedStatus: false
        }
        })
        .then((response) => {
          this.activeTeacherData = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    searchLeavedTeacher(page = 1) {
      axios.get("/teacher/search/result?page=" + page, {
        params: {
          name: this.searchData.name,
          teacher_no: this.searchData.teacher_no,
          gender: this.searchData.gender,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          campus_id: this.$store.state.user.campus_id,
          leavedStatus: true
        }
      })
        .then((response) => {
          this.leavedTeacherData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      }
    },

    delete(id) {
      axios.delete("/teacher/delete/" + id)
      .then(response => {
        if (response.data.success == true) {
            this.isLoading = true;
            this.searchActiveTeacher();
            this.searchLeavedTeacher();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Delete Error->", error);
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    excelDownload(status) {
      this.isLoading = true;
      axios.get('/teacher/excelDownload', {
        params: {
          name: this.searchData.name,
          teacher_no: this.searchData.teacher_no,
          gender: this.searchData.gender,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          campus_id: this.$store.state.user.campus_id,
          status: status
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.isLoading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', status + `_teacher_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "login"
            });
          }
          this.isLoading = false;
        });
    },

    templateDownload() {
      this.isLoading = true;
      axios.get('/teacher/templateDownload', {
        params: {
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'teacher_import_template.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
      },

    chooseFile() {
      this.$refs.fileUploader.value = '';
      document.getElementById('teacher-upload').click();
    },

    onTeacherFileChange() {
      this.invalidFile = false;
      let selectedFile = document.getElementById('teacher-upload').files;
      if (selectedFile.length) {
        let file = selectedFile[0];
        if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.teacherFileName = file.name;
          this.teacherFile = file;
        } else {
          this.invalidFile = true;
        }
      }
      this.disabled = false;
    },

    importFile() {
      this.submitted = true;
      this.disabled = true;
      if (this.$v.$invalid) {
        this.disabled = false;
        return;
      } else {
        this.isLoading = true;
        let data = new FormData();
        data.append('teacher_xlsx', this.teacherFile);
        data.append('campus_id', this.$store.state.user.campus_id);
        data.append('created_id', this.$store.state.user.id);
        axios.post("teacher/importTeacher", data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          if (response.data.success == true) {
            this.$notification.success(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
            this.disabled = false;
            this.getActiveTeacherList();
            this.getLeavedTeacherList();
            this.clearFile();
          } else {
            this.isLoading = false;
            this.disabled = false;
            alert(response.data.message);
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
      }
    },

    clearFile() {
      this.teacherFile = '';
      this.teacherFileName = '';
      document.getElementById('teacher-upload').value = '';
      this.submitted = false;
      this.disabled = false;
      this.invalidFile = false;
    },
  },

  mounted() {
    this.isLoading = true;
    this.getActiveTeacherList();
    this.getLeavedTeacherList();
  },
};
