import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
  name: "classList",
  components: {
    MainLayout,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    flatPickr,
    Loading,
    CONFIG
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      classList: {},
      academicYearArr: [],
      gradeArr: [],
      campus_id: '',
      grade_id: 0,
      academic_year: '',
      section: '',
      start_date: null,
      end_date: null,
      selectedGrade: 0,
      selectedYear: 0,
      search_status: false,
      currentDate: null
    };
  },

  methods: {
    searchClass(page = 1) {
      this.isLoading = true;
      var request = {
        params: {
          grade_id: this.grade_id,
          academic_year: this.academic_year,
          section: this.section,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/class/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.classList = response.data;
          this.isLoading = false;
          this.currentDate = new Date().toISOString().slice(0, 10);
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Search API Error", error);
        });
    },

    reset() {
      this.selectedGrade = this.selectedYear = 0;
      this.grade_id = '';
      this.academicYearArr = [{
        'id': '0',
        'text': 'Select Academic Year'
      }]
      this.academic_year = '';
      this.section = '';
      this.start_date = null;
      this.end_date = null;
    },

    getClassList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/class/list?page=" + page, request)
        .then(response => {
          this.search_status = false;
          this.classList = response.data;
          this.isLoading = false;
          this.currentDate = new Date().toISOString().slice(0, 10);
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class List API Error", error);
        });
    },

    getGradeName(campus_id = 1) {
      axios.get('getGradeName?id=' + campus_id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "All"
        }];
        this.gradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    confirmDelete(id) {
      this.checkClass(id);
    },

    checkClass(id) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/class/check/" + id, request)
        .then(response => {
          if (response.data.status == 'alreadyuse') {
            alert(response.data.message);
            return;
          }
          if (response.data.success == true) {
            if (window.confirm("Are you sure you want to delete?")) {
              this.deleteClass(id);
            } else {
              console.log("no");
            }
          }
        })
    },

    deleteClass(id) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.delete("/class/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getClassList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Delete Error->", error.response.data);
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      if (fieldName == "grade_id") {
        if (val == 0) {
          this.academicYearArr = [{
            'id': '0',
            'text': 'Select Academic Year'
          }]
        } else {
          this.academicYearArr = [];
          this.id = 0;
          this.getAcademicYearByGradeId(val);
        }
      }
    },

    getAcademicYearByGradeId(grade_id) {
      axios.get("/getAcademicYearByGradeId", {
        params: {
          grade_id: grade_id,
          campus_id: store.state.user.campus_id
        }
      })
        .then((response) => {
          let $defaultSelectOption = [{
            'id': '0',
            'text': 'Select Academic Year'
          }
          ];
          this.academicYearArr = $defaultSelectOption.concat(response.data.academicYearArr);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/class/create"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "classEdit",
        params: {
          id: id
        }
      });
    },

    showDetail(classId) {
      this.$router.push({
        name: "classDetail",
        params: {
          id: classId
        }
      });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/class/excelDownload', {
        params: {
          grade_id: this.grade_id,
          academic_year: this.academic_year,
          section: this.section,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: store.state.user.campus_id,
          search_status: this.search_status
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `class_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.isLoading = true;
    this.getAcademicYearByGradeId(this.grade_id);
    this.getGradeName();
    this.getClassList();
  },
};
