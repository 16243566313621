import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import {
  required,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";
import store from "@/store";

export default {
  name: "StudentPass",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config : { 
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() -1)
      },
      isLoading: false,
      disabled: false,
      studentData: {},
      parentData: {},
      gradeFeeData: {},
      dob: '',
      gradeArr: [],
      grade: 0,
      religion: '',
      section: '',
      student_image: '',
      selectedGrade: 0,
      selectedSection: 0,
      selectedReligion: 0,
      maxGradeId: 0,
      religionArr: null,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      sectionArr: [{
        id: 0,
        text: 'Choose Section'
      }],
      submitted: false,
      noSectionMsg:'',
      class_id: this.$route.query.class_id,
    };
  },

  validations: {
    grade: {
      required
    },
    religion: {
      required
    },
    section: {
      required
    },
    studentData: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100)
      },
      gender: {
        required
      },
      nationality: {
        maxLength: maxLength(100)
      },
      weight: {
        maxLength: maxLength(20)
      },
      height: {
        maxLength: maxLength(20)
      },
      sibling: {
        numeric,
        maxLength: maxLength(2)
      },
      hobby: {
        maxLength: maxLength(1000)
      },
      allergic_food: {
        maxLength: maxLength(1000)
      },
      special_topic: {
        maxLength: maxLength(1000)
      },
      blood: {
        maxLength: maxLength(20)
      },
      previous_school: {
        maxLength: maxLength(200)
      },
    },
  },

  computed: {
    ...mapGetters([
      'getUser',
      'studentNo'
    ]),
  },

  methods: {
    getGradeFee(gradeId, request) {
      axios.get("getGradeFee/" + gradeId, request)
        .then(res => {
          this.gradeFeeData = res.data[0];
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    onProfileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = e => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.student_image = e.target.result;
            }
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg() {
      this.student_image = '';
      document.getElementById('studentImage').value = '';
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getSection(gradeId, request, selectedClassId) {
      axios.get("/class/getSection/" + gradeId, request).then((response) => {
        if (response.data[0].length > 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }].concat(response.data[0]);
          this.selectedSection = this.section = selectedClassId;
          this.noSectionMsg = '';
          this.isLoading = false;
        } else {
          this.noSectionMsg = 'Please create class to show section.';
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.isLoading = false;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getStudentEdit() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id,
          result: 'notSet'
        }
      }

      axios.get("/student/detail/" + this.$route.params.id, request)
        .then(response => {
          if(response.data) {
            this.studentData = response.data;
            this.student_image = this.studentData.profile_path;
            // this.studentData.grade_id == this.maxGradeId ? 
            // this.selectedGrade = this.grade = this.studentData.grade_id :
            // this.selectedGrade = this.grade = this.studentData.grade_id + 1;
            this.selectedReligion = this.religion = this.studentData.religion;
            var request = {
              params: {
                campus_id: store.state.user.campus_id,
                academic_year: this.$route.query.year_id
              }
            }
            this.getSection(this.grade, request, this.selectedSection);
            this.getGradeFee(this.studentData.grade_id, request);
            this.getParentDetail(this.studentData.parent_id);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Detail API Error", error);
        });
    },

    getParentDetail(parentId) {
      axios.get("/parent/detail/" + parentId)
        .then(response => {
          this.parentData = response.data[0];
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Parent Detail API Error", error);
        });
    },

    getMaxGradeByCampus (){
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/grade/getMaxGradeIdByCampus", request).then((response) => {
        this.maxGradeId = response.data;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }        
        this.isLoading = false;
      });
    },

    getGradeName() {
      axios.get('getGradeNameByAcademicYear?id=' + this.$route.query.year_id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "All"
        }];
        this.gradeArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },
    
    getGradeByClassId() {
      axios.get('/grade/getGrade/' + this.$route.query.class_id)
      .then(res => {
        this.selectedGrade = this.grade = res.data.grade_id;
        this.selectedSection = res.data.id;
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.$invalid || this.grade == 0 || this.grade == '' || this.section == 0 || this.section == '' || this.religion == 0 || this.religion == '') {
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        update_class_student_id: this.$route.params.id,
        parent_id: this.parentData.id,
        name: this.studentData.name,
        student_image: this.student_image,
        student_image_old: this.studentData.profile_path,
        dob: this.studentData.dob,
        c_student_no: this.studentData.c_student_no,
        entry_no: this.studentData.entry_no,
        gender: this.studentData.gender,
        nationality: this.studentData.nationality,
        grade: this.grade,
        result: 1,
        religion: this.religion,
        section: this.section,
        weight: this.studentData.weight,
        blood: this.studentData.blood,
        height: this.studentData.height,
        sibling: this.studentData.sibling,
        hobby: this.studentData.hobby,
        allergic_food: this.studentData.allergic_food,
        previous_school: this.studentData.previous_school,
        special_topic: this.studentData.special_topic,
        campus_id: store.state.user.campus_id,
        updated_id: store.state.user.id,
        class_id: this.$route.query.class_id,
        year_id: this.$route.query.year_id
      }

      axios.post("/student/comingYear/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Setting Student Pass Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "studentList"
            });
          } else if (response.data.success == false) {
            this.disabled = false;
            console.log("error");
            this.$notification.error(response.data.err_msg, {
              timer: 3,
              position: "bottomCenter"
            });
          } else {
            this.disabled = false;
            console.log("error");
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Setting Student Pass API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.isLoading = true;
    this.getMaxGradeByCampus();
    this.getGradeName();
    this.getReligion();
    this.getGradeByClassId();
    this.getStudentEdit();
  },
};
