import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "noticeDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      noticeData: [],
      noticeArr: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getNotice() {
      axios.get('getNotice')
        .then(res => {
          this.noticeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get(`notice/detail/${this.$route.params.id}`, request)
      .then(res => {
        if (res.data) {
          this.noticeData = res.data;
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Notice Detail API Error", error);
      })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getNotice();
    this.getDetail();
  },
};
