import Vue from 'vue';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "GradeCreate",
  components: {
    MainLayout,
  },

  data() {
    return {
      inputData: {
        grade_name: null
      },
      editData: {
        grade_name: null
      },
      gradeInfo: [],
      firstname_ErrMsg: '',
      submitted: false,
    };
  },

  validations: {
    inputData: {
      grade_name: {
        required,
        maxLength: maxLength(10)
      }
    },
  },

  methods: {
    reset() {
      if (this.editData.grade_name != null) {
        this.gradeInfo.push(Vue.util.extend({}, this.editData));
        this.editData = {};
      }
      this.inputData = {};
    },

    cloneForm() {
      this.editData = {};
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.gradeInfo.push(Vue.util.extend({}, this.inputData));
      this.inputData = {};
      this.submitted = false;
    },

    removeClone(index) {
      Vue.delete(this.gradeInfo, index);
    },

    editClone(index) {
      if (this.inputData.grade_name != null) {
        this.gradeInfo.push(Vue.util.extend({}, this.inputData));
      }
      let data = this.gradeInfo[index];
      this.editData.grade_name = data.grade_name;
      this.inputData = this.gradeInfo[index];
      Vue.delete(this.gradeInfo, index);
    },

    create() {
      this.firstname_ErrMsg = '';
      if (this.gradeInfo.length <= 0) {
        this.firstname_ErrMsg = 'Please Add Grade Data';
        return;
      }
      axios.post('grade/create', {
        gradeInfo: this.gradeInfo,
        login_id: store.state.user.id
      })
        .then(res => {
          if (res.status == 200) {
            this.$notification.success("Grade Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'gradeList'
            })
          } else if (res.status == 201) {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.$notification.error("Something Wrong And Check Your Data That Already Exist or Not", {
              timer: 3,
              position: "bottomCenter"
            });
          }
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        })
    }
  }
};
