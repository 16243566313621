import Vue from 'vue';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../../store';
import axios from "axios";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "SubjectEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      tmpResult: false,
      campusGradeArr: null,
      campus_grade_id: '',
      old_grade_id: 0,
      inputData: {
        campus_grade_id: '',
        id: 0,
        subject_name: '',
        description: '',
        grade_name: '',
      },
      hidden: false,
      subjectInfo: '',
      tmp_input: [],
      firstname_ErrMsg: ''
    };
  },

  validations: {
    inputData: {
      campus_grade_id: {
        required
      },
      subject_name: {
        required,
        maxLength: maxLength(100)
      },
      description: {
        maxLength: maxLength(1000)
      },
    }
  },

  methods: {
    getCampusGradeName(campus_id = 1) {
      axios.get('getAcademicYearCampusGradeName?id=' + campus_id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "Select"
        }];
        this.campusGradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    changeEvent(val, fieldName) {
      this.tmpResult = false;
      this.$set(this, fieldName, val);
    },

    selectEvent({
      id
    }, fieldName) {
      id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
    },

    reset() {
      this.resetData();
    },

    getAllSubjectByCampusGradeId() {
      this.isLoading = true;
      axios.get("subject/get-subject", {
        params: {
          campus_grade_id: this.$route.params.campusGradeId
        }
      }).then((response) => {
        if (response.data[0] != undefined) {
          this.subjectInfo = response.data;
          this.inputData.grade_name = this.subjectInfo[0].grade_name;
          this.inputData.campus_grade_id = this.subjectInfo[0].campus_grade_id;
          this.campus_grade_id = this.subjectInfo[0].campus_grade_id;
        }
        this.isLoading = false;
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    showCreate() {
      this.$router.push({
        path: "/subject/create"
      });
    },

    resetData() {
      this.inputData.id = '';
      this.inputData.subject_name = '';
      this.inputData.description = '';
    },

    editClone(index) {
      this.hidden = true;
      this.addData();
      this.resetData();
      this.tmpInputData(this.subjectInfo[index]);
      this.inputData = this.subjectInfo[index];
      Vue.delete(this.subjectInfo, index);
    },

    addData() {
      if (this.inputData.campus_grade_id && this.inputData.subject_name) {
        this.subjectInfo.push(Vue.util.extend({}, this.inputData));
      }
    },

    tmpInputData(originalData) {
      this.tmp_input.campus_grade_id = originalData.campus_grade_id;
      this.tmp_input.id = originalData.id;
      this.tmp_input.subject_name = originalData.subject_name;
      this.tmp_input.description = originalData.description;
    },

    resetEdit() {
      this.resetEditData();
      this.resetData();
      this.hidden = false;
    },

    resetEditData() {
      if (this.tmp_input.campus_grade_id && this.tmp_input.subject_name) {
        this.subjectInfo.push(Vue.util.extend({}, this.tmp_input));
      }
      this.tmp_input = [];
    },

    removeClone(index) {
      Vue.delete(this.subjectInfo, index);
    },

    cloneForm() {
      this.tmpResult = false;
      this.firstname_ErrMsg = '';
      this.tmp_input = [];
      this.hidden = true;
      if (this.$v.$invalid) {
        this.tmpResult = true;
        return;
      }
      if (this.subjectInfo.length < 20) {
        this.old_grade_id = this.campus_grade_id;
        this.subjectInfo.push(Vue.util.extend({}, this.inputData));
        this.resetData();
        this.tmpResult = false;
      } else {
        alert('Subject can be created at most 20.');
      }
    },

    subjectEdit() {
      if (this.tmp_input.campus_grade_id && this.tmp_input.subject_name) {
        this.resetEdit();
      }
      this.isLoading = true;
      this.firstname_ErrMsg = '';
      if (this.subjectInfo.length <= 0) {
        this.firstname_ErrMsg = 'Please Add Subject Data';
        this.isLoading = false;
        return;
      }
      var id = this.$route.params.campusGradeId
      this.tmpResult = false;
      axios.post('subject/update/' + id, {
        subjectInfo: this.subjectInfo,
        length: this.subjectInfo.length,
        login_id: store.state.user.id,
        campus_grade_id: this.$route.params.campusGradeId,
      })
        .then(res => {
          if (res.data.status == 200) {
            this.$notification.success("Subject Edit Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'subjectList'
            })
          } else {
            this.$notification.success("something went wrong!", {
              timer: 3,
              position: "bottomCenter"
            });
            this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.errors = error.response.data.errors;
          this.isLoading = false;
        })
    }
  },
  mounted() {
    this.getCampusGradeName();
    this.getAllSubjectByCampusGradeId();
  },
};
