import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
    required,
    maxLength,
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../store';

export default {
    name: "classEdit",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            disabled: false,
            classData: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            academicYearArr: null,
            gradeArr: [],
            selectedGrade: 0,
            selectedYear: 0,
            academic_year: '',
            grade_id: '',
            isError: false,
            submitted: false,
        };
    },

    validations: {
        grade_id: {
            required
        },
        academic_year: {
            required
        },
        classData: {
            section: {
                required,
                maxLength: maxLength(20)
            },
            remark: {
                maxLength: maxLength(1000)
            },
        },
    },

    methods: {
        getGradeName(campus_id = 1) {
            axios.get('getGradeName?id=' + campus_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "Select"
                }];
                this.gradeArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
            if (fieldName == "grade_id") {
                this.selectedYear = this.academic_year = 0;
                this.getAcademicYearByGradeId(val);
            }
        },

        getAcademicYearByGradeId(grade_id) {
            axios.get("/getAcademicYearByGradeId", {
                params: {
                    grade_id: grade_id,
                    campus_id: store.state.user.campus_id
                }
            })
                .then((response) => {
                    let $defaultSelectOption = [{
                        'id': '0',
                        'text': 'Select Academic Year'
                    }];
                    this.academicYearArr = $defaultSelectOption.concat(response.data.academicYearArr);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Get Academic Year API Error", error);
                });
        },

        getClassEdit() {
            var request = {
              params: {
                  campus_id: store.state.user.campus_id
              }
            }
            axios.get("/class/detail/" + this.$route.params.id, request)
                .then(response => {
                    this.classData = response.data;
                    this.selectedGrade = this.grade_id = this.classData.grade_id;
                    this.academic_year = this.selectedYear = this.classData.academic_year;
                    this.remark = this.classData.remark;
                    this.getAcademicYearByGradeId(this.grade_id);
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Class Detail API Error", error);
                });
        },

        confirmUpdate() {
            this.submitted = true;
            if (this.classData.start_date && this.classData.end_date && new Date(this.classData.end_date) <= new Date(this.classData.start_date)) {
                this.isError = true;
            } else {
                this.isError = false;
            }
            if (this.$v.$invalid || this.grade_id == '' || this.grade_id == 0 || this.academic_year == '' || this.academic_year == 0 || this.section == '' || this.section == 0 || this.isError) {
                return;
            }
            this.update();
        },

        update() {
            this.disabled = true;
            var request = {
                grade_id: this.grade_id,
                academic_year: this.academic_year,
                section: this.classData.section,
                remark: this.classData.remark,
                start_date: this.classData.start_date,
                end_date: this.classData.end_date,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id
            }
            axios.post("/class/update/" + this.$route.params.id, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 200) {
                        this.$notification.success("Class Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "classList"
                        });
                    } else if (response.status == 201) {
                        this.isLoading = false;
                        this.disabled = false;
                        this.$notification.error("Class data is already exist.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.isLoading = false;
                    console.log("Class Edit API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/class/list"
            });
        },
    },
    
    mounted() {
        this.isLoading = true;
        this.getClassEdit();
        this.getGradeName();
    },
};
