import flatPickr from 'vue-flatpickr-component';
import 'vue-loading-overlay/dist/vue-loading.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "RequestList",
  components: {
    MainLayout,
    flatPickr,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {
        teacher_name: '',
        parent_name: '',
        start_date: '',
        end_date: '',
      },
      chatRequestData: {
        data: [],
      },
      chatRoomTitle: '-----------------------',
      multiSelect: false,
      roomCode: null,
      messages: [],
      messageList: [],
      unapprovedCount: 0,
      checkAll: false,
      isLoading: false,
      activeIndex: null,
      config: CONFIG,
    };
  },

  methods: {
    async getChatRequest(page = 1) {
      await axios.get("chat/room/request/list?page=" + page, {
        params : {
          usertype: this.$store.state.user.role == 1 ? 'admin' : 'staff'
        }
      }).then((response) => {
        this.chatRequestData = response.data;
        this.chatRoomTitle = '-----------------------';
        this.roomCode = null;
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    searchChat(page = 1) {
      this.isLoading = true;
      axios.get("chat/room/request/search?page=" + page, {
        params: {
          usertype: this.$store.state.user.role == 1 ? 'admin' : 'staff',
          teacher_name: this.searchData.teacher_name,
          parent_name: this.searchData.parent_name,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
      }
      })
      .then((response) => {
        this.chatRequestData = response.data;
        this.chatRoomTitle = '-----------------------';
        this.roomCode = null;
        this.messages = [];
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
      });
    },

    reset() {
      this.searchData.teacher_name = '';
      this.searchData.parent_name = '';
      this.searchData.start_date = '';
      this.searchData.end_date = '';
    },

    async changeRoom(roomCode, title) {
      this.isLoading = true;
      this.chatRoomTitle = title;
      this.roomCode = roomCode;
      this.checkAll = false;
      this.messageList = [];
      this.messages = [];
      this.unapprovedCount = 0;
      await axios.get("chat/room/" + roomCode + "/messages/request")
      .then((response) => {
        if (response.data) {
          for (const message of response.data.messages) {
            const date = new Date(message.datetime * 1000);
            message.datetime = this.formatDateToAMPM(date);
          }
          this.messages = response.data.messages;
          this.unapprovedCount = this.messages.filter(message => message.is_approved == this.config.CHAT_REQUEST_STATUS.PENDING).length;
        } else {
          window.location.reload();
        }
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
      });
      this.isLoading = false;
    },

    async approveMessage(messageId = null) {
      this.isLoading = true;
      if (messageId) {
        this.messageList = [messageId];
      }
      await axios.post("chat/room/" + this.roomCode + "/messages/approve", {
        messages: this.messageList,
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
      });
      await this.changeRoom(this.roomCode, this.chatRoomTitle);
      if (this.messages.length > 0) {
        this.isLoading = false;
      }
    },

    async rejectMessage(messageId = null) {
      this.isLoading = true;
      if (messageId) {
        this.messageList = [messageId];
      }
      await axios.post("chat/room/" + this.roomCode + "/messages/reject", {
        messages: this.messageList,
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
      });
      await this.changeRoom(this.roomCode, this.chatRoomTitle);
      if (this.messages.length > 0) {
        this.isLoading = false;
      }
    },

    updateMessageList(e, messageId) {
      if (e.target.checked) {
        this.messageList.push(messageId);
      } else {
        var filtersList = this.messageList.filter( ele => ele != messageId);
        this.messageList = filtersList;
      }
      if (this.messageList.length == this.unapprovedCount) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },

    selectAll() {
      var checkboxes;
      if (this.checkAll) {
        this.checkAll = false;
        this.messageList = [];
        checkboxes = document.querySelectorAll('.chat-message .cb-label input[type="checkbox"]');
        checkboxes.forEach(checkbox => checkbox.checked = false);
      } else {
        this.checkAll = true;
        checkboxes = document.querySelectorAll('.chat-message .cb-label input[type="checkbox"]');
        var selectMessages = this.messages.filter(message => message.is_approved == this.config.CHAT_REQUEST_STATUS.PENDING);
        this.messageList = selectMessages.map(message => message.id);
        checkboxes.forEach(checkbox => checkbox.checked = true);
      }
    },

    formatDateToAMPM(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const formattedHours = String(hours).padStart(2, '0');

      return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
    },

    toggleActive(index) {
      this.activeIndex = index;
    },

    toggleSelect() {
      this.messageList = [];
      this.checkAll = false;
      this.multiSelect = !this.multiSelect;
    },

    isActive(index) {
      return this.activeIndex === index;
    }
  },

  mounted() {
    this.isLoading = true;
    this.getChatRequest();
  },
};
