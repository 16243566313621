import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import {
  required
} from "vuelidate/lib/validators";
import store from '@/store';

export default {
  name: "CampusGradeCreate",
  components: {
    MainLayout,
    Select2Multiple
  },

  data() {
    return {
      isLoading: false,
      campusGradeData: {
        acd_year_id: null,
        grade_id: null,
        campus_id: store.state.user.campus_id,
        login_id: store.state.user.id
      },
      periodArr: [],
      gradeArr: [],
      submitted: false,
      disabled: false,
      campus_name: store.state.user.campus_master.campus_name
    };
  },

  validations: {
    campusGradeData: {
      acd_year_id: {
        required
      },
      grade_id: {
        required
      }
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('period/periodList')
        .then(res => {
          this.periodArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getGrade() {
      axios.get("grade/gradeList")
        .then(res => {
          this.gradeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      this.disabled = true;
      axios.post("campusGrade/create", this.campusGradeData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Campus Grade Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'campusGradeList'
            })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch((error) => {
          this.disabled = false;
          this.isLoading = false;
          if (error.response) {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
          }
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getGrade();
    this.getAcademicYear();
  },
};
