import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import {
  mapGetters
} from "vuex";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "StudentList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      name: '',
      parentNo: '',
      grade_id: '',
      campus_id: '',
      section_id: '',
      gender: '',
      year: '',
      selectedGrade: 0,
      selectedSection: 0,
      selectedGender: 0,
      selectedYear: 0,
      gradeArr: [],
      campusGradeArr: [],
      sectionArr: [{
        "id": 0,
        "text": "All"
      }],
      academicYearArr: null,
      studentList: {},
      leavedStudentList: {},
      genderArr: [{
          id: 0,
          text: 'All'
        },
        {
          id: 1,
          text: 'Male'
        },
        {
          id: 2,
          text: 'Female'
        }
      ],
      isLoading: false,
      search_status: false,
      leaved_search_status: false,
      submitted: false,
      invalidFile: false,
      disabled: false,
      templatelLink: '',
      studentFile: '',
      studentFileName: '',
      maxGradeId: 0,
      clickDisabled: false,
      checkAll: false,
      nextPageCheckAll: false,
      selectedCb: [],
      searchSelectedCb: [],
      unSelectedCb: [],
      idArray: [],
      passFailDisable: false,
      tmpClasses: [],
      saveIndex: null,
      requiredClass: false,
      requiredYear: false,
      classArr: null,
      year_id: 0,
      class_id: 0,
      passFailResult: 0,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  validations: {
    studentFile: {
      required
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
            campus_id: store.state.user.campus_id
        }
    })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    selectAll() {
      this.selectedCb = [];
      if (!this.checkAll) {
        if(this.search_status) {
          this.searchSelectedCb = this.idArray;
          this.nextPageCheckAll = true;
          this.checkAll = true;
          // this.unSelectedCb = [];
        } else {
          this.selectedCb = this.idArray;
          this.nextPageCheckAll = true;
          this.checkAll = true;
          // this.unSelectedCb = [];
        }
      } else {        
        this.checkAll = false;
        this.nextPageCheckAll = false;
        this.selectedCb = [];
        this.searchSelectedCb = [];
      }
    },

    updateCheckall(e, sId){
      let maxCbTotal = this.studentList.total;
      if (!e.target.checked) {
        this.unSelectedCb = this.unSelectedCb.concat(sId);
        this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
      } else {
        const filtersList = this.unSelectedCb.filter(element => element !== sId);
        this.unSelectedCb = filtersList;
        
        this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
      }
      // if allcheck is clicked and then single uncheck ( checkAll is false and nexPage is all checked )
      if(!this.nextPageCheckAll) {
        if (this.search_status) {
          if(maxCbTotal == this.searchSelectedCb.length){
            this.checkAll = true;
          } else{
            this.checkAll = false;
          }
        } else {
          if(maxCbTotal == this.selectedCb.length){
            this.checkAll = true;
          } else{
            this.checkAll = false;
          }
        }
      } else {
        if(maxCbTotal == this.studentList.total  - this.unSelectedCb.length){
          this.checkAll = true;
        } else{
          this.checkAll = false;
        }
      }
    },

    getCampusGradeName(campus_id = 1) {
      axios.get('getCampusGradeName?id=' + campus_id, {
          params: {
              campus_id: store.state.user.campus_id
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "All"
          }];
          this.campusGradeArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
  },

    getGradeNameByAcademicYear(yearId) {
      axios.get('getGradeNameByAcademicYear?id=' + yearId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "All"
        }];
        this.gradeArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getMaxGradeByCampus() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/grade/getMaxGradeIdByCampus", request).then((response) => {
          this.maxGradeId = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getClass($yearId) {
      axios.get("class/classList/" + $yearId , {
        params: {
            campus_id: this.$store.state.user.campus_id,
        }
      })
      .then(res => {
        let $defaultSelectOption = [{
          'id': 0,
          'text': 'Select Class'
        }];
        this.classArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getStudentList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      // axios.get("/student/list?page=" + page, request)
      axios.get("/student/active/list?page=" + page, request)
        .then(response => {
          this.search_status = false;
          this.studentList = response.data;
          this.idArray = [];
          this.studentList.data.forEach(e => {
            if(e.status !== 1 && e.result == null) {
              this.idArray.push(e.student.current_year_class_student.length > 0 ? e.student.current_year_class_student[0].id : e.student.class_student[0].id);
            }   
            if (e.result != null) {
              this.unSelectedCb.push(e.student.current_year_class_student.length > 0 ? e.student.current_year_class_student[0].id : e.student.class_student[0].id);
            }
          });

          if (this.checkAll || this.nextPageCheckAll) {
            this.unSelectedCb = new Set(this.unSelectedCb);
            let removedArr = this.idArray.filter((name) => {
              return !this.unSelectedCb.has(name);
            });

            this.selectedCb = this.selectedCb.concat(removedArr);
            this.selectedCb = [ ...new Set(this.selectedCb)] ;
            this.idArray = this.idArray.concat(this.idArray);
            this.idArray = [ ...new Set(this.idArray)];
            this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student List API Error", error);
        });
    },

    getLeavedStudentList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/student/leavedList?page=" + page, request)
        .then(response => {
          this.leaved_search_status = false;
          this.leavedStudentList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Leaved Student Lists API Error", error);
        });
    },

    searchStudent() {
      this.isLoading = true;
      this.searchSelectedCb = [];
      this.checkAll = false;
      this.unSelectedCb = [];
      this.idArray = [];
      this.nextPageCheckAll = false;
      this.searchActiveStudent();
      this.searchLeavedStudent();
    },

    searchActiveStudent(page = 1) {
      var request = {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: store.state.user.campus_id,
          leavedStatus: false
        }
      }
      axios.get("/student/search/result?page=" + page, request)
        .then(response => {
          this.isLoading = false;
          this.search_status = true;
          this.studentList = response.data;
          this.idArray = [];
          this.studentList.data.forEach(e => {
            if(e.status !== 1 && e.result == null) {
              this.idArray.push(e.student.current_year_class_student.length > 0 ? e.student.current_year_class_student[0].id : e.student.class_student[0].id);
            }   
            if (e.status === 1 && e.result == null) {
              this.unSelectedCb.push(e.student.current_year_class_student.length > 0 ? e.student.current_year_class_student[0].id : e.student.class_student[0].id);
            }
          });
          
          if (this.checkAll || this.nextPageCheckAll) {

            this.unSelectedCb = new Set(this.unSelectedCb);
            let removedArr = this.idArray.filter((name) => {
              return !this.unSelectedCb.has(name);
            });

            this.searchSelectedCb = this.searchSelectedCb.concat(removedArr);
            this.searchSelectedCb = [ ...new Set(this.searchSelectedCb)] ;
            this.idArray = this.idArray.concat(this.idArray);
            this.idArray = [ ...new Set(this.idArray)];
            this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Active Student Search API Error", error);
        });
    },

    searchLeavedStudent(page = 1) {
      var request = {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: store.state.user.campus_id,
          leavedStatus: true
        }
      }

      axios.get("/student/search/result?page=" + page, request)
        .then(response => {
          this.leaved_search_status = true;
          this.leavedStudentList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Leaved Student Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.parentNo = '';
      this.selectedGrade = this.selectedSection = this.selectedGender = this.selectedYear = 0;
      this.grade_id = '';
      this.section_id = '';
      this.gender = '';
      this.year = '';
    },

    confirmDelete(id, studentId, parentId) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteStudent(id, studentId, parentId);
      }
    },

    deleteStudent(id, studentId, parentId) {
      var request = {
        params: {
          student_id: studentId,
          campus_id: store.state.user.campus_id
        }
      }
      axios.delete("/student/delete/" + id + '/' + parentId, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getStudentList();
            this.getLeavedStudentList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Delete Error->", error);
        });
    },

    confirmActivate(id, parentId) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateStudent(id, parentId);
      }
    },

    activateStudent(id, parentId) {
      axios.delete("/student/activate/" + id + '/' + parentId)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getStudentList();
            this.getLeavedStudentList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Activate Error->", error);
        });
    },
    
    checkPassFailValidate (idArr, result) {
      this.passFailDisable = true;
      this.passFailResult = result;
      var request = {
        search_status: this.search_status,
        checkAll: this.checkAll,
        nextPageCheckAll : this.nextPageCheckAll,
        unSelectedCb: this.unSelectedCb,
        student_ids: idArr,
        result: result,
        campusMaxGrade: this.maxGradeId,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }

      if (this.search_status) {
        var searchRequest = {
            name: this.name,
            parent_no: this.parentNo,
            grade_id: this.grade_id,
            section_id: this.section_id,
            gender: this.gender,
            year: this.year,
            campus_id: store.state.user.campus_id,
            leavedStatus: false
        }
        request = Object.assign( {}, searchRequest, request );
      }
      
      axios.post("/student/checkPassFail", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        if (response.data.success == true) {
          this.passFailDisable = true;
          this.$bvModal.show('modal-1');
        } else {
          this.passFailDisable = false;
          alert(response.data.msg);
      }
      })
    },

    setPassFail(idArr, result, classId, yearId) {
      this.proceed = true;
      if (classId == 0 && this.proceed) {
        this.requiredClass = true;
      } else {
        this.requiredClass = false;
      }
      if (yearId == 0 && this.proceed) {
        this.requiredYear = true;
      } else {
        this.requiredYear = false;
      }
      if (this.requiredClass == false && this.requiredYear == false) {
        var msg = result == 1 ? "Are you sure you want to update student pass?" : 
                                "Are you sure you want to update student fail?";                        
        if (window.confirm(msg)) {                        
          this.passFailDisable = true;
          this.isLoading = true;
          this.$bvModal.hide('modal-2');
          var request = {
            search_status: this.search_status,
            checkAll: this.checkAll,
            nextPageCheckAll : this.nextPageCheckAll,
            unSelectedCb: this.unSelectedCb,
            student_ids: idArr,
            result: result,
            campusMaxGrade: this.maxGradeId,
            campus_id: store.state.user.campus_id,
            created_id: store.state.user.id,
            selected_class_id: classId,
            selected_year_id: yearId
          }

          if (this.search_status) {
            var searchRequest = {
                name: this.name,
                parent_no: this.parentNo,
                grade_id: this.grade_id,
                section_id: this.section_id,
                gender: this.gender,
                year: this.year,
                campus_id: store.state.user.campus_id,
                leavedStatus: false
            }
            request = Object.assign( {}, searchRequest, request );
          }

          axios.post("/student/setPassFail", request, {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => {
            if (response.status == 204) {
              this.submitted = false;
              this.getSection(this.grade, {
                params: {
                  campus_id: store.state.user.campus_id
                }
              });
              this.passFailDisable = false;
              this.isLoading = false;
              return;
            }
            if (response.data.success == true) {
              this.isLoading = false;
              this.passFailDisable = false;
              this.checkAll = false;
              this.selectedCb = [];
              if(this.search_status) {
                this.searchStudent();
              } else {
                this.getStudentList();
                this.getLeavedStudentList();
              }
              this.checkAll = false;
              this.selectedCb = [];
              var resMsg = result == 1 ? "Setting Student Pass Successfully" : "Setting Student Fail Successfully";
              this.$notification.success(resMsg, {
                timer: 3,
                position: "bottomCenter"
              });
            } else {
              this.isLoading = false;
              this.passFailDisable = false;
              alert(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            this.passFailDisable = false;
            console.log("Student Pass/Fail API Error", error.errors);
          }); 
        } else {
          this.passFailDisable = false;
          this.isLoading = false;
        }
      }
    },

    showCreate() {
      this.$router.push({
        path: "/student/create"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "studentEdit",
        params: {
          id: id
        }
      });
    },

    showDetail(id, leavedStatus) {
      this.$router.push({
        name: "studentProfile",
        params: {
          id: id,
          leavedStatus: leavedStatus
        }
      });
    },

    editStudentPassFail(id, result) {
      var msg = result == 1 ? "Are you sure you want to update this student pass?" : 
                              "Are you sure you want to update this student fail?";
      if (window.confirm(msg)) {
        this.clickDisabled = true;
        var request = {
          update_class_student_id: id,
          campus_id: store.state.user.campus_id,
          updated_id: store.state.user.id,
          result: result
        }

        axios.post("/student/edit/passFail", request, {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => {
            if (response.data.success == true) {
              this.clickDisabled = false;
              this.getStudentList();
              this.getLeavedStudentList();
              this.isLoading = false;
              var successMsg = result == 1 ? "Setting Student Pass Successfully" : "Setting Student Fail Successfully";
              this.$notification.success(successMsg, {
                timer: 3,
                position: "bottomCenter"
              });
            } else {
              this.clickDisabled = false;
              this.$notification.error(response.data.message, {
                timer: 3,
                position: "bottomCenter"
              });
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.clickDisabled = false;
            this.isLoading = false;
            console.log("Setting Student Pass/Fail API Error", error.errors);
          });
      } else {
        this.clickDisabled = false;
      }
    },

    showEditToFail(id, classId) {
      this.$router.push({
        name: "studentFail",
        params: {
          id: id
        },
        query: {
          class_id: classId,
        }
      });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);

      if (fieldName == 'grade_id') {
        this.sectionArr = [];
        this.section_id = '';
        this.selectedSection = '';
        this.getSection(this.grade_id, this.year);
      }
      if (fieldName == 'year') {
        this.gradeArr = [];
        this.sectionArr = [];
        this.section_id = '';
        this.selectedSection = '';
        this.grade_id = '';
        this.selectedGrade = '';
        this.getCampusGradeName(this.year);
        this.getGradeNameByAcademicYear(this.year);
      }
      if (fieldName == 'class_id') {
        this.proceed = false;
      }
      if (fieldName == 'year_id') {
        this.proceed = false;
        this.class_id = 0;
      }
    },

    getSection($gradeId, $yearId) {
      axios.get("/class/getSection/" + $gradeId, {
          params: {
            academic_year: $yearId,
            campus_id: store.state.user.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'All'
          }];
          this.sectionArr = $defaultSelectOption.concat(res.data[0]);
          this.selectedSection = 0;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "login"
            });
          }
          this.isLoading = false;
        });
    },

    getTemplateLink() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("student/getStudentTemplateLink", request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        this.templatelLink = res.data;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    chooseFile() {
      this.$refs.fileUploader.value = '';
      document.getElementById('student-upload').click();
    },
    
    showModel(id, result) {
      this.currentId = id;
      this.passFailResult = result;
      this.$bvModal.show('modal-1');
    },
    
    hideModal1() {
      this.class_id = 0;
      this.year_id = 0;
      this.requiredClass = false;
      this.requiredYear = false;
      this.$bvModal.hide('modal-1');
    },
    
    proceedNextClass(id, result, classId, yearId) {
      this.proceed = true;
      if (classId == 0 && this.proceed) {
        this.requiredClass = true;
      } else {
        this.requiredClass = false;
      }
      if (yearId == 0 && this.proceed) {
        this.requiredYear = true;
      } else {
        this.requiredYear = false;
      }
      if (this.requiredClass == false && this.requiredYear == false) {
        if (result == 1) {
          this.$router.push({
            name: "studentPass",
            params: {
              id: id
            },
            query: {
              class_id: classId,
              year_id: yearId
            }
          });
        } else {
          this.$router.push({
            name: "studentFail",
            params: {
              id: id
            },
            query: {
              class_id: classId,
              year_id: yearId
            }
          });
        }
      }
    },

    onStudentFileChange() {
      this.invalidFile = false;
      let selectedFile = document.getElementById('student-upload').files;
      if (selectedFile.length) {
        let file = selectedFile[0];
        if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.studentFileName = file.name;
          this.studentFile = file;
        } else {
          this.invalidFile = true;
        }
      }
      this.disabled = false;
    },

    importFile() {
      this.submitted = true;
      this.disabled = true;
      if (this.$v.$invalid) {
        this.disabled = false;
        return;
      } else {
        this.isLoading = true;
        let data = new FormData();
        data.append('student_xlsx', this.studentFile);
        data.append('campus_id', store.state.user.campus_id);
        data.append('created_id', store.state.user.id);
        axios.post("student/importStudent", data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          if (response.data.success == true) {
            this.$notification.success(response.data.msg, {
              timer: 3,
              position: "bottomCenter"
            });
            this.disabled = false;
            this.getStudentList();
            this.getLeavedStudentList();
            this.clearFile();
          } else {
            this.isLoading = false;
            this.disabled = false;
            alert(response.data.msg);
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
      }
    },

    clearFile() {
      this.studentFile = '';
      this.studentFileName = '';
      document.getElementById('student-upload').value = '';
      this.submitted = false;
      this.disabled = false;
      this.invalidFile = false;
    },

    excelDownload(status) {
      this.isLoading = true;
      axios.get('/student/excelDownload', {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: store.state.user.campus_id,
          status: status,
          search_status: this.search_status
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', status + `_student_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    deleterow() {
      const rowAdd = document.getElementsByClassName('row-add');
      Array.from(rowAdd).forEach(element => {
        element.remove();
      });
      const cols = document.getElementsByClassName('addSpan');
      Array.from(cols).forEach(element => {
        element.removeAttribute('rowspan');
        element.removeAttribute('class');
      });
    },

    addrow(classes, grades, rowIndex) {
      this.deleterow();
      if( this.saveIndex == rowIndex) {
        this.saveIndex = null;
        return;
      }
      this.saveIndex = rowIndex;
      this.tmpClasses = [];
      this.tmpClasses = classes.map((item, index) => {
        const gradeName = this.campusGradeArr.find(e => e.id === item.class.campus_grade_id);
        return {
          student_id: item.student_id,
          campus_grade_id: item.class.campus_grade_id,
          grade_name: gradeName ? gradeName.text : null,
          section: item.class.section,
          stu_grade_id: grades[index].id,
          stu_grade_result: grades[index].result,
          class_id: item.class.id,
        }
      });
      this.tmpClasses.splice(0,1);

      var table = document.getElementById('active-table');
      var originalRowCount = table.rows.length;
      var afterRow = document.getElementsByClassName('row-' + (rowIndex + 1));
      var checkedRow = document.getElementsByClassName('row-' + rowIndex);
      var rowTd = checkedRow[0].children;
      for(let i = 0; i < 6; i++) {
        rowTd[i].setAttribute('rowspan', this.tmpClasses.length + 1);
        rowTd[i].className = 'addSpan';
      }
      var fragment = document.createDocumentFragment();
      this.tmpClasses.forEach( e => {
        var newRow = document.createElement("tr");
        newRow.classList = ['row-add'];
        var rowTpl = `
        <td data-label="Class Name">
            ${e.grade_name} (${e.section})
        </td>
        `
        if(e.stu_grade_result != null) {
          rowTpl = rowTpl + `
          <td data-label="Exam Result" class="exam-result">
              <div class="d-flex justify-content-center">
                  <div>
                      <i class="fa-solid fa-thumbs-up pass disabledPassFail" ></i>
                  </div>
                  <div class="fail-box">
                      <i class="fa-solid fa-thumbs-down fail disabledPassFail"></i>
                  </div>
              </div>
          </td>
          <td></td>
          `
        }else {
          rowTpl = rowTpl + `
          <td data-label="Exam Result" class="exam-result">
              <div class="d-flex justify-content-center">
                  <div>
                      <i class="fa-solid fa-thumbs-up pass" onclick="window.studentListComponent.showModel(${e.stu_grade_id}, ${1})"></i>
                  </div>
                  <div class="fail-box">
                      <i class="fa-solid fa-thumbs-down fail" onclick="window.studentListComponent.showModel(${e.stu_grade_id}, ${0})"></i>
                  </div>
              </div>
          </td>
          <td></td>
          `
        }
        newRow.innerHTML = rowTpl;

        fragment.appendChild(newRow);
      })
      if (rowIndex < originalRowCount - 1) {
          table.tBodies[0].insertBefore(fragment, afterRow[0]);
      } else {
          table.tBodies[0].appendChild(fragment);
      }
      
    }
  },
  
  mounted() {
    this.isLoading = true;
    this.getMaxGradeByCampus();
    this.getTemplateLink();
    this.getAcademicYear();
    this.getCampusGradeName();
    this.getStudentList();
    this.getLeavedStudentList();
    window.studentListComponent = this;
  },
};
