import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "timetableDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      class_name: "",
      academic_year: 0,
      timeTableData: [],
      days: [],
      time: [],
    };
  },

  computed: {
    timetableDataMap() {
      const timetableMap = {};
      if (Array.isArray(this.timeTableData)) {
        this.timeTableData.forEach(item => {
          timetableMap[item.rowColIndex] = item.subject_name;
        });
      }
      return timetableMap;
    }
  },

  methods: {
    getTimetableMeta() {
      axios.get("/timetableMeta/getDataForTimetable/" + this.$route.params.id)
        .then((response) => {
          this.days = response.data[0][0];
          this.time = response.data[0][1];
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTimeTableDetail() {
      axios.get("/timetable/detail/" + this.$route.params.id)
        .then(response => {
          this.timeTableData = response.data;
          if (this.timeTableData.length != 0) {
            this.class_name = response.data[0].class_name;
            this.academic_year = response.data[0].period_name;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getTimeTableDetail();
    this.getTimetableMeta();
  },
};
