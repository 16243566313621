import Footer from "@/components/layout/admin/FooterPage.vue";
import axios from "axios";
import store from '@/store';
import {
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";

export default {
  name: "UpdatePassword",
  components: {
    Footer,
  },

  data() {
    return {
      updatePass: {
        newPassword: '',
        confirmPassword: '',
      },
      submitted: false,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
    };
  },

  validations: {
    updatePass: {
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    },
  },

  methods: {
    updatePassword() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.disabled = true;
      axios.post(`updatePassword/${this.$store.state.user.id}`, this.updatePass);
      this.$store.commit('logout');
      this.$router.push({
        name: 'login'
      });
    }
  },
};
