import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "noticeCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      config : { 
        minDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() )
      },
      time_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      noticeData: {
        send_to: 0,
        class: [],
        student_id: [],
        teacher_id: [],
      },
      allTea: [],
      allStu: [],
      classArr: [],
      submitted: false,
      studentVali: false,
      campusId: '',
      classIdForStu: null,
      disabled: false,
      isLoading:false,
      classZeroValidation: false,
      errors: '',
      classVali: false,
      teacherVali: false,
      classForStu: false,
      classArrForStudent: [],
      defaultSelectOption:  [{
                              'id': 0,
                              'text': 'All'
                            }],
      teacherZeroValidation: false,
      studentZeroValidation: false
    };
  },

  validations: {
    noticeData: {
      title: {
        required,
        maxLength: maxLength(500)
      },
      message: {
        required,
        maxLength: maxLength(5000)
      },
      send_date: {
        required
      },
      send_time: {
        required
      },

    }
  },

  methods: {
    changeEvent(val, fieldName) {
      if (fieldName == 'studentVali' && val != null) {
        this.studentVali = false;
      }
      if (fieldName == 'teacherVali' && val != null) {
        this.teacherVali = false;
      }
      if (fieldName == 'classVali' && val != null) {
        this.classVali = false;
      }
    },

    getClass() {
      axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } } )
      .then(res => {
        if (res.data.length != 0) {
          this.classArr = this.defaultSelectOption.concat(res.data);
          this.classArrForStudent = this.defaultSelectOption.concat(res.data);
        }
        this.isLoading = false;
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getStudent(id) {
      if(id != null || id != '') {
        this.classForStu = false;
      }
      this.allStu = [];
      this.noticeData.student_id = null;
      axios.get('student/getallStudent',{ params: { campus_id: this.campusId, class_id: id } } )
      .then(res => {
        if (res.data.length > 0) {
          this.allStu = this.defaultSelectOption.concat(res.data);
        }
        this.isLoading = false;
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
      this.noticeData.name = '';
    },

    getTeacher() {
      axios.get('teacher/getallTeacher',{ params: { campus_id: this.campusId } } )
      .then(res => {
        if (res.data[0].length != 0) {
          this.allTea = this.defaultSelectOption.concat(res.data[0]);
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    sendToAll() {
      this.noticeData.teacher_id = [];
      this.classIdForStu = null;
      this.noticeData.student_id = [];
    },

    sendToTea() {
      this.noticeData.class = [];
      this.classIdForStu = null;
      this.noticeData.student_id = [];
    },

    sendToStu() {
      this.noticeData.class = [];
      this.noticeData.teacher_id = [];
    },

    create() {
      this.submitted = true;
      this.validation();
      if (this.$v.$invalid || this.classVali == true || this.teacherVali == true || this.studentVali == true || this.classForStu == true) {
        return
      }
      this.insert();
    },

    validation() {
      if (this.noticeData.send_to == 0 && this.noticeData.class.length == 0) {
        this.classVali = true;
      } else this.classVali = false;
      if (this.noticeData.send_to == 1 && this.noticeData.teacher_id.length == 0) {
        this.teacherVali = true;
      } else this.teacherVali = false;
      if (this.noticeData.send_to == 2 && this.classIdForStu == null) {
        this.classForStu = true;
      } else this.classForStu = false;
      if (this.noticeData.send_to == 2 && this.noticeData.student_id == null && this.classIdForStu != 0) {
        this.studentVali = true;
      } else this.studentVali = false;
    },

    insert() {
      // if (this.classIdForStu == 0) {
      //   this.noticeData.classIdForStu = null;
      // } else {
      //   this.noticeData.classIdForStu = this.classIdForStu;
      // }
      this.isLoading = true;
      this.disabled = true;
      this.noticeData.loginId = this.$store.state.user.id;
      this.noticeData.loginRole = this.$store.state.user.role;
      this.noticeData.campusId = this.$store.state.user.campus_id;
      this.noticeData.classIdForStu = this.classIdForStu;
      if (this.noticeData.send_to == 0 & this.noticeData.class != null) {
        if (this.noticeData.class.includes('0')) {
          if (this.noticeData.class[1]) {
            this.classZeroValidation = true;
            this.isLoading = false;
            this.disabled = false;
            return;
          } else {
            for (let i = 0; i < (this.classArr.length); i++) {
              this.noticeData.class[i] = this.classArr[i].id;
            }
          }
        }
      }
      this.classZeroValidation = false;

      if (this.noticeData.send_to == 1 & this.noticeData.teacher_id != null) {
        if (this.noticeData.teacher_id.includes('0')) {
          if (this.noticeData.teacher_id[1]) {
            this.teacherZeroValidation = true;
            this.isLoading = false;
            this.disabled = false;
            return;
          } else {
            for (let i = 0; i < (this.allTea.length); i++) {
              this.noticeData.teacher_id[i] = this.allTea[i].id;
            }
          }
        }
      }
      this.teacherZeroValidation = false;

      if (this.noticeData.send_to == 2 & this.classIdForStu != null & this.noticeData.student_id != null) {
        if (this.noticeData.student_id.includes('0')) {
          if (this.noticeData.student_id[1]) {
            this.studentZeroValidation = true;
            this.isLoading = false;
            this.disabled = false;
            return;
          } else {
            for (let i = 0; i < (this.allStu.length); i++) {
              this.noticeData.student_id[i] = this.allStu[i].id;
            }
          }
        }
      }
      this.studentZeroValidation = false;

      axios.post('notice/create', this.noticeData)
      .then(res => {
        if (res.data.success == true) {
          this.$notification.success("Notice Added Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: 'noticeList'
          })
        }
        else {
          this.errors = res.data.errors;
          this.disabled = false;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.disabled = false;
        this.errors = error.response.data.errors;
      })
     },
  },

  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.getClass();
    this.getTeacher();
  },
};
