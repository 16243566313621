import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "timetableEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      submitted: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      academic_year: [],
      selectedClass: 0,
      class_name: "",
      classArr: [],
      class_name_required: '',
      subjectCount: [],
      subjectArr: [],
      section_exist: '',
      timeTableData: [],
      days: [],
      time: [],
      dayCount: 0,
      timeCount: 0,
      selectedSubjects: []
    };
  },

  computed: {
    timetableDataMap() {
      const timetableMap = {};
      if (Array.isArray(this.timeTableData)) {
        this.timeTableData.forEach(item => {
          timetableMap[item.rowColIndex] = item.subject_id;
        });
      }
      return timetableMap;
    },
  },

  methods: {
    getTimetableMeta($classId) {
      axios.get("/timetableMeta/getDataForTimetable/" + $classId)         
      .then((response) => {
        this.days = response.data[0][0];
        this.time = response.data[0][1];
        this.dayCount = this.days.length;
        this.timeCount = this.time.length;
        this.selectedSubjects = Array.from({ length: this.dayCount }, () =>
          Array.from({ length: this.timeCount }, () => null)
        );
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getTimeTableDetail($classId) {
      axios.get("/timetable/detail/" + $classId)
        .then(response => {
          this.timeTableData = response.data;
          if (this.timeTableData.length != 0) {
            this.class_name = response.data[0].class_name;
            this.academic_year.period_name = response.data[0].period_name;
            this.academic_year.acd_year_id = response.data[0].period_id;
          }
          this.getSubjectByClassId(this.$route.params.id);
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getSubjectByClassId($classId) {
      axios.get("/class/subjectByClassId/" + $classId, {
        params: {
          acd_year_id: this.academic_year.acd_year_id,
          campus_id: this.$store.state.user.campus_id
        }
      })         
      .then((response) => {
        this.subjectArr = response.data.subjectArr[0];
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    changeEvent(dayId, timeId, val) {      
      const itemToUpdate = this.timeTableData.find(item => item.day_meta_id === dayId && item.time_meta_id === timeId);
      if (itemToUpdate) {
        itemToUpdate.subject_id = val;
      }
    },

    update() {
      this.isLoading = true;
      this.submitted = true;

      axios.post("timetable/update", {
          class_id: this.$route.params.id,
          acd_year_id: this.academic_year.acd_year_id,
          campus_id: this.$store.state.user.campus_id,
          login_id: this.$store.state.user.id,
          timeTableData: this.timeTableData,
        })
        .then(response => {
          if (response.data) {
              this.$notification.success("Timetable Update Successfully", {
                timer: 3,
                position: "bottomCenter",
              });
              this.$router.push({
                name: "timetableList"
              });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },

  mounted() { 
    this.isLoading = true;
    const selectedClass = this.$route.params.id;
    this.getTimetableMeta(selectedClass);
    this.getTimeTableDetail(selectedClass);
  },
};
