import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
    required,
    maxLength,
    integer
} from "vuelidate/lib/validators";
import upLoad from '@/assets/img/upload_photo3.jpg';
import axios from 'axios';
import store from "@/store";
import CONFIG from "@/assets/js/config.js";

export default {
    name: "inOutcomeCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
    },

    data() {
        return {
            imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
            acaYear: null,
            inOutData: {
                type: null,
                title: '',
                payment_method_id: null,
                payment_date: '',
                amount: '',
                payment_screenshot: '',
                incharge_name: '',
                remark: '',
                loginID: '',
                campus_id: '',
                class_id: ''
            },
            academic_year: '',
            classArr: null,
            academicYearArr: null,
            paymentMethodArr: null,
            submitted: false,
            ssImage: '',
            upLoad,
            campusId: store.state.user.campus_id,
            disabled: false,
        };
    },

    validations: {
        inOutData: {
            type: {
                required,
            },
            class_id: {
                required
            },
            title: {
                required,
                maxLength: maxLength(100)
            },
            payment_method_id: {
                required
            },
            payment_date: {
                required
            },
            amount: {
                required,
                integer,
                maxLength: maxLength(9)
            },
            incharge_name: {
                required,
                maxLength: maxLength(100)
            },
          
            remark: {
                maxLength: maxLength(1000)
            },
           
        },

        // ssImage :{
        //     required,
        // }

    },

    methods: {
        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.isLoading = false;
                    this.getClass(this.academicYear.id)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getPaymentMethod() {
            axios.get('paymentMethod/getPaymentMethodArray')
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Payment Method'
                    }];
                    this.paymentMethodArr = $defaultSelectOption.concat(res.data[0]);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        removeImg() {
            this.ssImage = '';
            document.getElementById('image').value = '';
        },

        imgUpload(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return null;
            } else {
                if (e.target.files[0].type.includes("image/")) {
                    if (e.target.files[0].type == "image/gif") {
                        this.$notification.error("File not supported!", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        return null;
                    }
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.target.result.length > 5242880) {
                            this.$notification.error(this.imageLargeMsg, {
                                timer: 3,
                                position: "bottomCenter",
                            });
                        } else {
                            this.ssImage = e.target.result;
                        }
                    }
                    reader.readAsDataURL(files[0]);
                } else {
                    this.$notification.error("File not supported!", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    return null;
                }
            }
        },

        create() {
            this.submitted = true;
            if (this.$v.$invalid) {
                return;
            }
            this.inOutData.payment_screenshot = this.ssImage;
            this.inOutData.loginID = this.$store.state.user.id;
            this.inOutData.campus_id = this.$store.state.user.campus_id;
            this.disabled = true;
            axios.post('transaction/create', this.inOutData)
                .then(res => {
                    if (res.data) {
                        this.$notification.success("Transaction Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'transactionList'
                        })
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.getPaymentMethod();
        this.getThisAcademicYear();
    }
};
