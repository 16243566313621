import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
    name: "SubjectDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            grade_name: '',
            subjectInfo: []
        };
    },

    methods: {
        getAllSubjectByCampusGradeId() {
            this.isLoading = true;
            axios.get("subject/get-subject", {
                params: {
                    campus_grade_id: this.$route.params.campusGradeId
                }
            }).then((response) => {
                if (response.data[0] != undefined) {
                    this.subjectInfo = response.data;
                    this.grade_name = this.subjectInfo[0].grade_name;
                }
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showList() {
            this.$router.push({
                path: "/subject/list"
            });
        },
    },
    mounted() {
        this.getAllSubjectByCampusGradeId();
    },
};
