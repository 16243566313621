import MainLayout from "@/components/layout/admin/MainLayout.vue";
import VueSlickCarousel from 'vue-slick-carousel';
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "HomeIndex",
  components: {
    MainLayout,
    VueSlickCarousel,
    Loading
  },

  data() {
    return {
      setting: {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "arrows": false,
        "dots": true,
        "speed": 300,
        "infinite": true,
        "autoplaySpeed": 3000,
        "autoplay": true,
        "responsive": [{
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 3,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
            }
          }
        ]
      },

      settingStaff: {
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "arrows": false,
        "dots": true,
        "speed": 300,
        "infinite": true,
        "autoplaySpeed": 3000,
        "autoplay": true,
        "responsive": [{
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 3,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
            }
          }
        ]
      },
      gradeArr: [],
      selectedGrade: 0,
      teacherList: {},
      studentList: {},
      payment: 0,
      income: 0,
      outcome: 0,
      balance: 0,
      slideCount: 0,
      noticeCount: 0,
      totalTeacherCount: 0,
      activeStudentCount: 0,
      leavedStudentCount: 0,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      hasClass: false,
      isStaff: true,
    }
  },

  methods: {
    getNoticecount () {
      axios.get("/notice/allNoticeCount", { params: { campus_id: this.$store.state.user.campus_id }})
        .then(response => {
          this.noticeCount = response.data;
        })
        .catch(error => {
          console.log("Notice Count API Error", error);
        });
    },

    getPaymentData() {
      axios.get("/payment/totalPayment", { params: { campus_id: this.$store.state.user.campus_id }})
        .then((response) => {
          this.payment = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Payment API Error", error);
        });
    },

    getTransactionInfo() {
      axios.get("/transaction/info", { params: { campus_id: this.$store.state.user.campus_id }})
        .then((response) => {
          this.income = response.data.income;
          this.outcome = response.data.outcome;
          this.balance = this.income - this.outcome;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Income Expense API Error", error);
        });
    },

    getLatestTeacherList() {
      axios.get("/teacher/latestList", { params: { campus_id: this.$store.state.user.campus_id }})
        .then((response) => {
          this.teacherList = response.data;
          this.slideCount = this.teacherList.length;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher List API Error", error);
        });
    },

    getTotalTeacherCount() {
      axios.get("/teacher/getAllTeacherCount", { params: { campus_id: this.$store.state.user.campus_id }})
        .then((response) => {
          this.totalTeacherCount = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Count API Error", error);
        });
    },

    getTotalStudentCount() {
      axios.get("/student/getAllStudentCount", { params: { campus_id: this.$store.state.user.campus_id }})
        .then((response) => {
          this.activeStudentCount = response.data.activeCount;
          this.leavedStudentCount = response.data.leavedCount;
        })
        .catch((error) => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Count API Error", error);
        });
    },

    getLatestStudentList() {
      axios.get("/student/latestList", { params: { campus_id: this.$store.state.user.campus_id }})
        .then(response => {
          this.studentList = this.prepareStudentClassName(response.data);
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student List API Error", error);
        });
    },

    prepareStudentClassName(studentList) {
      for (const student of studentList) {
        var className = '';
        for (var i = 0; i < student.current_year_class_student.length; i++) {
          className = className + student.current_year_class_student[i].class.campus_grade.grade.grade_name + '(' + student.current_year_class_student[i].class.section + ')' + ', ';
        }
        className = className.replace(/,\s*$/, '');
        student.classNames = className;
      }
      return studentList;
    }
  },
  
  mounted() {
    this.isLoading = true;
    this.getNoticecount();
    this.getPaymentData();
    this.getTransactionInfo();
    this.getLatestTeacherList();
    this.getTotalTeacherCount();
    this.getTotalStudentCount();
    this.getLatestStudentList();
    this.$store.state.user.role == 2 ? this.isStaff = true : this.isStaff = false;
  },
};
