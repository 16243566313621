import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import {
    required,
    maxLength,
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "HolidayCreate",
    components: {
        MainLayout,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            date: "",
            description: "",
            submitted: false,
            disabled: false,
        };
    },

    validations: {
        date: {
            required
        },
        description: {
            maxLength: maxLength(1000)
        },
    },

    methods: {
        async confirmRegister() {
            this.submitted = true;
            this.disabled = true;
            if (this.$v.$invalid || this.date == '' || this.date == 0) {
                this.disabled = false;
                return;
            }
            this.register();
        },

        register() {
            this.disabled = true;
            var request = {
                date: this.date,
                description: this.description,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id,
            }

            axios.post("/holiday/create", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.success == true) {
                        this.$notification.success("Holiday Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        if (this.$route.query.rd_back == 'holiday') {
                            this.$router.push({
                                name: "holidayCreate"
                            });
                        } else {
                            this.$router.push({
                                name: "holidayList"
                            });
                        }
                    } else {
                        this.disabled = false;
                        this.$notification.error(response.data.message, {
                          timer: 3,
                          position: "bottomCenter"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.
                        console.log("Holiday Create API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/holiday/list"
            });
        },
    },

};
