import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import Loading from "vue-loading-overlay";
import store from '../../store';
import "vue-loading-overlay/dist/vue-loading.css";
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";

export default {
  name: "timetableMetaList",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
    AdvancedLaravelVuePaginate,
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      selectedPeriod: 0,
      selectedClass: 0,
      timeTableMetaData: {},
      disabled: true,

      classArr: [],
      periodArr: []
    };
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
            campus_id: store.state.user.campus_id
        }
      }).then(res => {
          this.periodArr = res.data.academicYearArr;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
        params: {
          campus_id: this.$store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTimeTableMeta(page = 1) {
      axios.get("timetableMeta/list?page=" + page, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(response => {
          this.timeTableMetaData = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeAcademicYearEvent($acd_year_id) {
      this.selectedClass = 0;
      this.getClass($acd_year_id);
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete("timetableMeta/delete/" + id)
        .then(response => {
          if (response.data.success == true) {
            this.getTimeTableMeta();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.selectedPeriod = 0;
      this.selectedClass = 0;
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get("timetableMeta/search?page=" + page, {
        params: {
          acd_year_id: this.selectedPeriod,
          class_id: this.selectedClass,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((response) => {
          this.timeTableMetaData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('timetableMeta/excelDownload', {
        params: {
          acd_year_id: this.selectedPeriod,
          class_id: this.selectedClass,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.isLoading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `timetable_meta_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getTimeTableMeta();
    this.getAcademicYear();
  },
};
