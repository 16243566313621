import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import upLoad from '@/assets/img/upload_photo3.jpg';
import store from '@/store';
import axios from 'axios';
import Vue from 'vue';

export default {
  name: "paymentEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      inputData: {
        payment_method: '',
        payment_date: '',
        received_amt: '',
        payment_screenshot: '',
        receiver: '',
        remark: '',
        id: '',
        class_id: '',
        class_name: '',
        studentName: '',
        imgShow: false,
        remove_ssImage: '',
        removeStatus: false,
        item_id: '',
      },
      ssImage: '',
      inputInfo: [],
      editData: [],
      loginId: store.state.user.id,
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      isLoading: false,
      upLoad,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      submitted: false,
      studentId: '',
      imgRemove: false,
      guardian1_name: '',
      visible: false,
      itemArr: [],
      itemAmtArr: [],
      class_name: '',
    };
  },

  validations: {
    inputData: {
      payment_method_id: {
        required
      },
      payment_date: {
        required
      },
      received_amt: {
        required,
      },
      receiver: {
        required,
        maxLength: maxLength(100)
      },
      remark: {
        maxLength: maxLength(1000)
      },
      item_id: {
        required
      }
    },

  },

  methods: {
    getItem() {
      axios.get('item/getItemForPayment')
        .then(res => {
          this.itemArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAmt(item_id) {
      var selectedItem = this.itemAmtArr.find(item => item.id == item_id);
      this.inputData.received_amt = selectedItem.p_item_amount;
    },

    getReceivedAmt() {
      axios.get('item/list')
        .then(res => {
          this.itemAmtArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    removeImg() {
      this.ssImage = '';
      document.getElementById('image').value = '';
      this.inputData.removeStatus = true;
    },

    getDetail() {
      var request = {
        params: {
          campus_id: this.campusId
        }
      }
      axios.get(`payment/editDetail/${this.$route.params.id}`, request)
        .then(res => {
          if (res.data) {
            this.inputInfo = res.data;
            this.studentId = this.inputInfo[0].student_id;
            this.guardian1_name = this.inputInfo[0].guardian1_name;
            this.class_name = this.inputInfo[0].class_name;
            this.defaultValue();
            this.isLoading = false;
          }
        })
        .catch(error => {
          this.studentId = '';
          if (error.response) {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
          }
          this.isLoading = false;
        })
    },

    defaultValue() {
      this.inputData.class_id = this.inputInfo[0].class_id;
      this.inputData.class_name = this.inputInfo[0].class_name;
      this.inputData.studentName = this.inputInfo[0].studentName;
    },

    imgUpload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return null;
      } else {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = (e) => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.ssImage = e.target.result;
              document.getElementById('image').value = '';
            }
          }
          reader.readAsDataURL(files[0]);
          this.inputData.imgShow = true;
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    addEditData() {
      this.inputInfo.push(Vue.util.extend({}, this.editData));
      this.editData = [];
    },

    addData() {
      if (this.inputData.item_id && this.inputData.payment_method && this.inputData.payment_date && this.inputData.received_amt && this.inputData.receiver) {
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
      }
    },

    reset() {
      this.submitted = false;
      if (this.editData.item_id != null) {
        this.addEditData();
      }
      this.inputData = [];
      this.defaultValue();
      this.ssImage = '';
    },

    cloneForm() {
      this.editData = [];
      this.submitted = true;
      if (this.$v.$invalid || this.inputData.payment_method_id == '' || this.inputData.payment_method_id == 0) {
        return;
      }
      if (this.inputInfo.length < 20) {
        var i = this.inputInfo.length;
        if (this.inputData.removeStatus == true) {
          this.inputData.remove_ssImage = this.inputData.payment_screenshot;
          this.inputData.payment_screenshot = null;
          this.inputData.img_path = null;
        }
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
        if (this.inputData.imgShow == true) {
          this.inputInfo[i].payment_screenshot = this.ssImage;
          this.inputData.imgShow = false;
        }
        this.inputData = [];
        this.defaultValue();
        this.ssImage = '';
      } else {
        alert('Payment can be created at most 20.');
      }
      this.submitted = false;
    },

    editClone(index) {
      this.addData();
      this.getEditData(this.inputInfo[index]);
      this.inputData = this.inputInfo[index];
      if (this.inputInfo[index].img_path) {
        this.ssImage = this.inputInfo[index].img_path;
      } else {
        this.ssImage = this.inputInfo[index].payment_screenshot;
      }
      Vue.delete(this.inputInfo, index);
    },

    getEditData(data) {
      this.editData.item_id = data.item_id;
      this.editData.class_id = data.class_id;
      this.editData.class_name = data.class_name;
      this.editData.student_id = data.student_id;
      this.editData.payment_method_id = data.payment_method_id;
      this.editData.received_amt = data.received_amt;
      this.editData.remark = data.remark;
      this.editData.payment_screenshot = data.payment_screenshot;
      this.editData.receiver = data.receiver;
      this.editData.studentName = data.studentName;
      this.editData.payment_date = data.payment_date;
      if (data.id) {
        this.editData.id = data.id;
        this.editData.img_path = data.img_path ? data.img_path : null;
      }
    },

    removeClone(index) {
      Vue.delete(this.inputInfo, index);
    },

    update() {
      this.reset();
      this.submitted = true;
      this.disabled = true;
      for (var i = 0; i < this.inputInfo.length; i++) {
        if (this.inputInfo[i]['payment_method'] == 0) {
          this.inputInfo[i]['payment_method'] = '';
        }
      }
      
      axios.post(`payment/update/${this.$route.params.id}`, {
        inputInfo: this.inputInfo,
        length: this.inputInfo.length,
        campus_id: this.campusId,
        loginId: this.loginId,
        class_id: this.inputInfo[0]['class_id'],
        studentId: this.studentId
      })
        .then(res => {
          if (res.data) {
            this.$notification.success("Payment Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "paymentList"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.errors = error.response.data.errors;
          this.disabled = false;
          this.isLoading = false;
        })
    }
  },

  mounted() {
    this.isLoading = true
    this.getReceivedAmt();
    this.getItem();
    this.getDetail();
    this.getPaymentMethod();
  },
};
