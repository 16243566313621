import VueMonthlyPicker from 'vue-monthly-picker';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";

export default {
    name: "HolidayList",
    components: {
        MainLayout,
        VueMonthlyPicker,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            selectedMonth: null,
            month: null,
            description: "",
            holidayMasterData: {},
        };
    },

    watch: {
      selectedMonth(newValue) {
        if (newValue) {
          const adjustedMonth = new Date(newValue);
          adjustedMonth.setMonth(adjustedMonth.getMonth() + 1); // Add one month to adjust for the offset
          this.month = adjustedMonth.toISOString().slice(0, 7); // Extract month and year
        } else {
          this.month = null;
        }
      },
    },

    methods: {
        reset() {
          this.month = null;
          this.selectedMonth = null;
        },

        searchHoliday(page = 1) {
            this.isLoading = true;
            axios.get('holiday/search?page=' + page, {
                params: {
                    month: this.month,
                    campus_id: store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data) {
                        this.holidayMasterData = res.data;
                    }
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        getAllHoliday(page = 1) {
            axios.get('holiday/list?page=' + page, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then((response) => {
                this.holidayMasterData = response.data;
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showCreate() {
            this.$router.push({
                path: "/holiday/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "holidayEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(id) {
            this.$router.push({
                name: "holidayDetail",
                params: {
                    id: id
                }
            });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteHoliday(id);
            } else {
                console.log("no");
            }
        },

        deleteHoliday(id) {
            this.isLoading = true;
            axios.delete("/holiday/delete/" + id)
                .then(response => {
                    if (response.data.success == true) {
                        this.getAllHoliday();
                    } else {
                        this.$notification.error("Holiday data is already deleted.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.getAllHoliday();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Holiday Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/holiday/excelDownload', {
                params: {
                    month: this.month,
                    campus_id: store.state.user.campus_id,
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `holiday_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getAllHoliday();
    },
};
